import styled from "styled-components";

import * as Style from "~/style";
import * as Icons from "~/icons";

// ============ //
// ↓↓↓ Date ↓↓↓ //
// ============ //

export const DateContainer = styled("div").attrs(() => ({
	backgroundColor: Style.Theme.bind("mode", {
		dark: Style.Constants.filter.dark.date.containerBackgroundColor,
	}),
}))`
	background-color: ${(props) => props.backgroundColor};
`;

export const Date = styled("div").attrs(() => ({
	backgroundColor: Style.Theme.bind("mode", {
		dark: Style.Constants.filter.dark.date.backgroundColor,
	}),
}))`
	padding: 40px ${Style.Constants.layout.mobile.padding}px;
	background-color: ${(props) => props.backgroundColor};
	border-radius: 0px 0px 40px 40px;
`;

export const DateTitle = styled("div").attrs(() => ({
	color: Style.Theme.bind("mode", {
		dark: Style.Constants.filter.dark.date.titleColor,
	}),
}))`
	margin-bottom: 20px;
	color: ${(props) => props.color};
	font-size: 14px;
	font-weight: 700;
`;

// ================ //
// ↓↓↓ Calendar ↓↓↓ //
// ================ //

export const DateCalendar = styled("div").attrs(() => ({
	color: Style.Theme.bind("mode", {
		dark: Style.Constants.filter.dark.date.calendarColor,
	}),
}))`
	color: ${(props) => props.color};
`;

// ======================= //
// ↓↓↓ Calendar Header ↓↓↓ //
// ======================= //

type CalendarHeader = { windowWidth: number };

export const DateCalendarHeader = styled("div")<CalendarHeader>`
	${Style.Snippets.flex("row", "space-between", "center")};
	margin: 0px auto 20px;
	width: ${(props) => (props.windowWidth < 375 ? `${props.windowWidth} px` : `${315}px`)};
`;

export const DateCalenderHeaderMonthYear = styled("div")`
	font-size: 22px;
	font-weight: 700;
`;

export const DateCalenderHeaderDropdownArrow = styled(Icons.DownArrow)`
	${Style.Snippets.square("10px")};
	margin-left: 10px;
`;

export const DateCalendarHeaderArrows = styled("div")`
	${Style.Snippets.grid(2, "1fr", 10)};
`;

export const DateCalendarHeaderArrowsLeft = styled(Icons.ChevronLeft)`
	${Style.Snippets.square("30px")};
`;

export const DateCalendarHeaderArrowsRight = styled(Icons.ChevronLeft)`
	${Style.Snippets.square("30px")};
	transform: rotate(180deg);
`;

// ===================== //
// ↓↓↓ Calendar Body ↓↓↓ //
// ===================== //

export const DateCalendarBody = styled("div")`
	margin: 0 auto;
	max-width: 315px;
`;

type Weekdays = { windowWidth: number };

export const DateCalendarBodyWeekdays = styled("div")<Weekdays>`
	${Style.Snippets.grid(7, "1fr")};
	margin: 0px auto 10px;
	width: ${(props) => (props.windowWidth < 375 ? `${props.windowWidth} px` : `${315}px`)};
`;

export const DateCalendarBodyWeekdaysDay = styled("div").attrs((props) => ({
	color: Style.Theme.bind("mode", {
		dark: Style.Constants.filter.dark.date.calendarBodyWeekdaysDayColor,
	}),
}))<Weekdays>`
	width: ${(props) =>
		props.windowWidth < 375 ? `${props.windowWidth / 7} px` : `${315 / 7}px`};
	color: ${(props) => props.color};
	font-size: 14px;
	font-weight: 700;
	text-align: center;
`;

export const DateCalendarBodyDays = styled("div")`
	${Style.Snippets.grid(7, "1fr")};
	margin: 0px auto;
	font-size: 14px;
`;

type Day = {
	inCurrentMonth: boolean;
	startDate: any;
	endDate: any;
	date: any;
	windowWidth: number;
};

export const DateCalendarBodyDaysDay = styled("div").attrs(() => ({
	colorUnmuted: Style.Theme.bind("mode", {
		dark: Style.Constants.filter.dark.date.calendarBodyDaysDayUnmutedColor,
	}),
	colorMuted: Style.Theme.bind("mode", {
		dark: Style.Constants.filter.dark.date.calendarBodyDaysDayMutedColor,
	}),
	backgroundColor: Style.Theme.bind("mode", {
		dark: Style.Constants.filter.dark.date.calendarBodyDaysDayBackgroundColor,
	}),
}))<Day>`
	${Style.Snippets.flex()};
	width: ${(props) =>
		props.windowWidth < 375 ? `${props.windowWidth / 7} px` : `${315 / 7}px`};
	height: ${315 / 7}px;
	color: ${(props) => (props.inCurrentMonth ? props.colorUnmuted : props.colorMuted)};
	background-color: ${(props) =>
		props.date >= props.startDate && props.date <= props.endDate
			? props.backgroundColor
			: "none"};
`;

// ====================== //
// ↓↓↓ Selected Range ↓↓↓ //
// ====================== //

export const DateRange = styled("div").attrs(() => ({
	color: Style.Theme.bind("mode", {
		dark: Style.Constants.filter.dark.date.rangeColor,
	}),
}))`
	${Style.Snippets.flex()};
	margin-top: 20px;
	color: ${(props) => props.color};
	font-size: 14px;
`;

export const DateRangeStart = styled("div").attrs(() => ({
	backgroundColor: Style.Theme.bind("mode", {
		dark: Style.Constants.filter.dark.date.rangeBackgroundColor,
	}),
}))`
	padding: 10px 15px;
	background-color: ${(props) => props.backgroundColor};
	border-radius: 5px;
`;

export const DateRangeArrow = styled(Icons.ChevronLeft)`
	${Style.Snippets.size("20px")};
	margin: 0px 10px;
	transform: rotate(180deg);
`;

export const DateRangeEnd = styled(DateRangeStart)``;
