import * as Spring from "react-spring";

export function body(searching: boolean) {
	return Spring.useSpring({
		from: { opacity: 0, transform: "translateY(40px)" },
		to: searching
			? { opacity: 0, transform: "translateY(40px)" }
			: { opacity: 1, transform: "translateY(0px)" }
	});
}

export function card() {
	return Spring.useSpring({
		from: { opacity: 0 },
		to: { opacity: 1 },
		delay: 300
	});
}
