import * as React from "react";
import moment from "moment";

import * as Context from "~/context";

import * as Styled from "../date.styled";

type Props = {
	width: number;
	nextMonthDays: number[];
	currentMonth: number;
	startDate: moment.Moment;
	endDate: moment.Moment;
};

export const NextMonthDays = (props: Props) => {
	const { width, nextMonthDays, currentMonth, startDate, endDate } = props;
	const { filters } = Context.Filters.useFiltersContext();

	const component = nextMonthDays.map((day: number, index: number) => {
		let nextMonth = currentMonth + 1;
		if (nextMonth > 11) nextMonth = 0;
		const month = filters.variables.months[nextMonth];

		return (
			<Styled.DateCalendarBodyDaysDay
				key={`next-${index}`}
				onClick={() =>
					filters.setters.setRange(nextMonth, day, filters.state.currentYear)
				}
				inCurrentMonth={false}
				startDate={startDate}
				endDate={endDate}
				date={moment([
					month === "January" ? filters.state.currentYear + 1 : filters.state.currentYear,
					nextMonth,
					day
				])}
				windowWidth={width}
			>
				{day}
			</Styled.DateCalendarBodyDaysDay>
		);
	});

	return <>{component}</>;
};
