import * as React from "react";

import * as Templates from "~/templates";
import * as Components from "~/components";

import atfImage from "~/images/atf.png";

import * as Parts from "./lib";
import * as Styled from "./index.styled";

export const Index = () => {
	return (
		<Templates.Layout>
			<Components.SEO title="Home" />
			<Styled.IndexContainer>
				<Introduction />
				<Parts.Description />
				<Parts.HandpickedModal />
				<Components.Footer />
			</Styled.IndexContainer>
		</Templates.Layout>
	);
};

const Introduction = () => {
	// Above The Fold Content
	return (
		<Styled.IndexIntroduction>
			<Styled.IndexATFImage src={atfImage} />
			<Parts.Header />
			<Parts.Handpicked />
		</Styled.IndexIntroduction>
	);
};
