import * as React from "react";

import * as Context from "~/context";
import * as Templates from "~/templates";
import * as Components from "~/components";

import * as Styled from "./search.styled";
import * as Springs from "./search.springs";
import * as Parts from "./lib";

export const Search = () => {
	const { search } = Context.Search.useSearchContext();
	const { results } = Context.Results.useResultsContext();

	const [input, setInput] = React.useState("");

	// React.useEffect(() => search.getters.getRecommendations(), []);

	function handleChange(event: React.ChangeEvent<HTMLInputElement>): void {
		setInput(event.currentTarget.value);
	}

	function handleSubmit(event: React.FormEvent<HTMLFormElement>): void {
		event.preventDefault();
		search.setters.setSearching(true);
		const expandedSearchBar = document.getElementById("search-bar");
		expandedSearchBar?.blur();
		results.getters.getResults(input);
	}

	return (
		<Templates.Layout>
			<Components.SEO title="Search" />
			<Styled.Search onSubmit={handleSubmit}>
				<Styled.SearchOverlay
					onClick={() => search.setters.setFilterModal(false)}
					isOpen={search.state.filterModal}
				/>
				<Parts.Filter />

				<Styled.SearchBar
					onClick={() => search.setters.setSearching(false)}
					onChange={handleChange}
					searching={search.state.searching}
					autoFocus
				/>
				<Styled.SearchBody>
					{search.state.searching ? "" : <Parts.Tray input={input} />}
					<SearchResults />
				</Styled.SearchBody>
			</Styled.Search>
		</Templates.Layout>
	);
};

// ====================== //
// ↓↓↓ Search Results ↓↓↓ //
// ====================== //

const SearchResults = () => {
	const { search } = Context.Search.useSearchContext();

	const animateSearchResults = Springs.searchResults(search.state.searching);

	return (
		<Styled.SearchResults style={animateSearchResults}>
			<SearchResultsHeader />
			<Parts.ResultCards />
		</Styled.SearchResults>
	);
};

// ============================= //
// ↓↓↓ Search Results Header ↓↓↓ //
// ============================= //

const SearchResultsHeader = () => {
	const { search } = Context.Search.useSearchContext();
	const { results } = Context.Results.useResultsContext();

	return (
		<Styled.SearchResultsHeader>
			<Styled.SearchResultsHeaderCountFilterContainer>
				<Styled.SearchResultsHeaderCount>
					{results.state.results.length} Results
				</Styled.SearchResultsHeaderCount>
				<Styled.SearchResultsHeaderFilter>
					Youtube > Relevance
				</Styled.SearchResultsHeaderFilter>
			</Styled.SearchResultsHeaderCountFilterContainer>
			<Styled.SearchResultsHeaderButtonContainer>
				<Styled.SearchResultsHeaderHomeButton to="/">
					<Styled.SearchResultsHeaderHomeIcon />
				</Styled.SearchResultsHeaderHomeButton>
				<Styled.SearchResultsHeaderFilterButton
					onClick={() => search.setters.setFilterModal(true)}
				>
					<Styled.SearchResultsHeaderFilterIcon />
				</Styled.SearchResultsHeaderFilterButton>
			</Styled.SearchResultsHeaderButtonContainer>
		</Styled.SearchResultsHeader>
	);
};
