import styled from "styled-components";

import * as Style from "~/style";
import * as Icons from "~/icons";

// =================== //
// ↓↓↓ Description ↓↓↓ //
// =================== //

export const Description = styled("div").attrs(() => ({
	padding: Style.Theme.bind("device", {
		mobile: Style.Snippets.px(Style.Constants.layout.mobile.padding),
		tablet: Style.Snippets.px(Style.Constants.layout.tablet.padding),
		desktop: Style.Snippets.px(Style.Constants.layout.desktop.padding),
		ultrawide: Style.Snippets.px(Style.Constants.layout.ultrawide.padding),
	}),
	color: Style.Theme.bind("mode", {
		dark: Style.Constants.home.dark.description.color,
	}),
	backgroundColor: Style.Theme.bind("mode", {
		dark: Style.Constants.home.dark.description.backgroundColor,
	}),
}))`
	padding: ${(props) => Style.Snippets.responsivePadding(props.theme.device, "50px")};
	padding-top: 50px;
	padding-bottom: 50px;
	color: ${(props) => props.color};
	background-color: ${(props) => props.backgroundColor};
	transform: translateY(-1px);
`;

export const DescriptionTitle = styled("h3")`
	margin-bottom: 20px;
	font-size: 26px;
	font-weight: 700;
	text-align: center;
`;

export const DescriptionSubtitle = styled("h4")`
	margin: 0px auto;
	max-width: 600px;
	font-size: 16px;
	text-align: center;
	line-height: 150%;
`;

// ================ //
// ↓↓↓ Examples ↓↓↓ //
// ================ //

export const DescriptionExamples = styled("div").attrs(() => ({
	width: Style.Theme.bind("device", {
		ultrawide: Style.Snippets.px(700),
		desktop: Style.Snippets.px(700),
		tablet: Style.Snippets.px(500),
		mobile: "100%",
	}),
}))`
	${Style.Snippets.grid(1, "auto", 30)};
	width: ${(props) => props.width};
	margin: 40px auto 60px;
`;

type Example = { justifySelf: string };

export const DescriptionExample = styled("div").attrs(() => ({
	width: Style.Theme.bind("device", {
		ultrawide: Style.Snippets.px(600),
		desktop: Style.Snippets.px(600),
		tablet: Style.Snippets.px(400),
		mobile: Style.Snippets.px(256),
	}),
	backgroundColor: Style.Theme.bind("mode", {
		dark: Style.Constants.home.dark.description.exampleBackgroundColor,
	}),
	boxShadow: Style.Theme.bind("mode", {
		dark: Style.Constants.home.dark.description.exampleShadow,
	}),
}))<Example>`
	${Style.Snippets.flex("row", "auto", "center")};
	height: 50px;
	width: ${(props) => props.width};
	justify-self: ${(props) => props.justifySelf};
	background-color: ${(props) => props.backgroundColor};
	border-radius: 8px;
	box-shadow: ${(props) => props.boxShadow};
`;

export const DescriptionExampleIcon = styled(Icons.Search).attrs(() => ({
	color: Style.Theme.bind("mode", {
		dark: Style.Constants.home.dark.description.exampleIconColor,
	}),
}))`
	${Style.Snippets.size(`${Style.Constants.search.mobile.icon}px`, "auto")};
	margin: 0px 15px 0px 20px;
	color: ${(props) => props.colorInterpolationFilters};
`;

export const DescriptionExampleText = styled("div").attrs(() => ({
	color: Style.Theme.bind("mode", {
		dark: Style.Constants.home.dark.description.exampleTextColor,
	}),
}))`
	color: ${(props) => props.color};
	font-size: 12px;
`;
