import styled from "styled-components";
import * as Gatsby from "gatsby";

import "../../../../node_modules/video-react/dist/video-react.css";

import * as Style from "~/style";
import * as Icons from "~/icons";

export const Result = styled("div")`
	${Style.Snippets.size("100vh", "100vw")};
`;

// ============== //
// ↓↓↓ Result ↓↓↓ //
// ============== //

export const ResultContainer = styled("div").attrs(() => ({
	height: Style.Theme.bind("device", {
		ultrawide: "80%",
		desktop: "80%",
		tablet: "80%",
		mobile: "100%",
	}),
	width: Style.Theme.bind("device", {
		ultrawide: "80%",
		desktop: "80%",
		tablet: "80%",
		mobile: "100%",
	}),
	margin: Style.Theme.bind("device", {
		ultrawide: Style.Snippets.px(Style.Constants.layout.ultrawide.padding),
		desktop: Style.Snippets.px(Style.Constants.layout.desktop.padding),
		tablet: Style.Snippets.px(Style.Constants.layout.tablet.padding),
		mobile: "0px",
	}),
	borderRadius: Style.Theme.bind("device", {
		ultrawide: "1rem",
		desktop: "1rem",
		tablet: "1rem",
		mobile: "0rem",
	}),
	color: Style.Theme.bind("mode", {
		dark: Style.Constants.result.dark.color,
	}),
	backgroundColor: Style.Theme.bind("mode", {
		dark: Style.Constants.result.dark.backgroundColor,
	}),
	boxShadow: Style.Theme.bind("mode", {
		dark: Style.Constants.result.dark.boxShadow,
	}),
}))`
	${Style.Snippets.flex("column", "auto", "auto")};
	margin: ${(props) => props.margin};
	margin-top: 0;
	margin-bottom: 0;
	width: auto;
	height: ${(props) => props.height};
	color: ${(props) => props.color};
	background-color: ${(props) => props.backgroundColor};
	border-radius: ${(props) => props.borderRadius};
	box-shadow: ${(props) => props.boxShadow};
	overflow: hidden;
`;

export const ResultBackButton = styled(Gatsby.Link).attrs(() => ({
	color: Style.Theme.bind("mode", {
		dark: Style.Constants.result.dark.backButtonColor,
	}),
	backgroundColor: Style.Theme.bind("mode", {
		dark: Style.Constants.result.dark.backButtonBackgroundColor,
	}),
}))`
	${Style.Snippets.square("35px")};
	position: absolute;
	top: 30px;
	left: 30px;
	z-index: 4;
	color: ${(props) => props.color};
	background-color: ${(props) => props.backgroundColor};
	border-radius: 5px;
	opacity: 0.7;
	transition: 0.1s ease-in-out;

	:hover {
		opacity: 1;
	}
`;

export const ResultBackButtonIcon = styled(Icons.ChevronLeft)``;

export const ResultHomeLink = styled(Gatsby.Link).attrs(() => ({
	color: Style.Theme.bind("mode", {
		dark: Style.Constants.result.dark.homeLinkColor,
	}),
	backgroundColor: Style.Theme.bind("mode", {
		dark: Style.Constants.result.dark.homeLinkBackgroundColor,
	}),
	backgroundColorActive: Style.Theme.bind("mode", {
		dark: Style.Constants.result.dark.homeLinkActiveBackgroundColor,
	}),
}))`
	${Style.Snippets.flex()};
	${Style.Snippets.size("74px", "100%")};
	color: ${(props) => props.color};
	background-color: ${(props) => props.backgroundColor};
	border-radius: 10px;
	transition: 0.2s ease-in-out;

	:active {
		background-color: ${(props) => props.backgroundColorActive};
	}
`;

// ============= //
// ↓↓↓ Video ↓↓↓ //
// ============= //

export const ResultVideo = styled("div").attrs(() => ({
	backgroundColor: Style.Theme.bind("mode", {
		dark: Style.Constants.result.dark.video.backgroundColor,
	}),
}))`
	position: relative;
	flex: 3;
	background-color: ${(props) => props.backgroundColor};
`;

// =================================== //
// ↓↓↓ Transcript / Next Container ↓↓↓ //
// =================================== //

export const TranscriptNextContainer = styled("div").attrs(() => ({
	columns: Style.Theme.bind("device", {
		ultrawide: "repeat(2, 1fr)",
		desktop: "repeat(2, 1fr)",
		tablet: "1fr",
		mobile: "1fr",
	}),
}))`
	display: grid;
	grid-template-columns: ${(props) => props.columns};
`;

// ================== //
// ↓↓↓ Transcript ↓↓↓ //
// ================== //

export const ResultTranscript = styled("div").attrs(() => ({
	padding: Style.Theme.bind("device", {
		ultrawide: Style.Snippets.px(40),
		desktop: Style.Snippets.px(40),
		tablet: Style.Snippets.px(40),
		mobile: Style.Snippets.px(30),
	}),
	backgroundColor: Style.Theme.bind("device", {
		ultrawide: Style.Colors.DARK4,
		desktop: Style.Colors.DARK4,
		tablet: Style.Colors.DARK4,
		mobile: Style.Colors.TRANSPARENT,
	}),
}))`
	flex: 1;
	padding: ${(props) => props.padding};
	background-color: ${(props) => props.backgroundColor};
	font-size: 16px;
	font-weight: 700;
	line-height: 150%;
`;

// ============ //
// ↓↓↓ Next ↓↓↓ //
// ============ //

export const ResultNextContainer = styled("div").attrs(() => ({
	padding: Style.Theme.bind("device", {
		ultrawide: Style.Snippets.px(40),
		desktop: Style.Snippets.px(40),
		tablet: Style.Snippets.px(40),
		mobile: Style.Snippets.px(30),
	}),
	backgroundColor: Style.Theme.bind("mode", {
		dark: Style.Constants.result.dark.next.backgroundColor,
	}),
}))`
	${Style.Snippets.flex("column", "center", "center")};
	padding: ${(props) => props.padding};
	background-color: ${(props) => props.backgroundColor};
`;

export const ResultNext = styled("div")`
	width: 100%;
`;

export const ResultNextTitle = styled("div").attrs(() => ({
	color: Style.Theme.bind("mode", {
		dark: Style.Constants.result.dark.next.titleColor,
	}),
}))`
	margin-bottom: 15px;
	color: ${(props) => props.color};
	font-size: 16px;
	font-weight: 700;
`;

// ==================== //
// ↓↓↓ Next Content ↓↓↓ //
// ==================== //

export const ResultNextContent = styled("div").attrs(() => ({
	color: Style.Theme.bind("mode", {
		dark: Style.Constants.result.dark.next.contentColor,
	}),
}))`
	/* ${Style.Snippets.grid(2, "1fr", 20)}; */
	display: flex;
	color: ${(props) => props.color};
	text-decoration: none;
`;

// ============================== //
// ↓↓↓ Next Content Thumbnail ↓↓↓ //
// ============================== //

export const ResultNextContentThumbnail = styled("div").attrs(() => ({
	boxShadow: Style.Theme.bind("mode", {
		dark: Style.Constants.result.dark.next.contentThumbnailShadow,
	}),
}))`
	${Style.Snippets.size("74px", "132px")};
	position: relative;
	flex-shrink: 0;
	margin-right: 20px;
	border-radius: 5px;
	box-shadow: ${(props) => props.boxShadow};
`;

export const ResultNextContentThumbnailImage = styled("img").attrs(() => ({
	alt: "Next Video Thumbnail",
}))`
	${Style.Snippets.fill()};
	background-color: white;
	border-radius: 5px;
`;

export const ResultNextContentThumnbailIcon = styled(Icons.Youtube).attrs(() => ({
	color: Style.Theme.bind("mode", {
		dark: Style.Constants.result.dark.next.contentThumbnailIconColor,
	}),
	backgroundColor: Style.Theme.bind("mode", {
		dark: Style.Constants.result.dark.next.contentThumbnailIconBackgroundColor,
	}),
}))`
	${Style.Snippets.size("40%", "auto")};
	position: absolute;
	right: 0;
	bottom: 0;
	padding: 3%;
	color: ${(props) => props.color};
	background-color: ${(props) => props.backgroundColor};
	border-radius: 5px 0px 4px 0px;
`;

// ========================= //
// ↓↓↓ Next Content Info ↓↓↓ //
// ========================= //

export const ResultNextContentInfo = styled("div")`
	width: 100%;
`;

export const ResultNextContentInfoTitle = styled("div").attrs(() => ({
	color: Style.Theme.bind("mode", {
		dark: Style.Constants.result.dark.next.contentInfoTitleColor,
	}),
}))`
	margin-bottom: 5px;
	color: ${(props) => props.color};
	font-size: 10px;
	font-weight: 700;
`;

export const ResultNextContentInfoDate = styled("div")`
	margin-bottom: 5px;
	font-size: 10px;
`;

export const ResultNextContentInfoDuration = styled("div")`
	font-size: 10px;
`;
