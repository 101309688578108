import * as React from "react";

import * as Context from "~/context";

import * as Styled from "./sort.styled";

export const Sort = () => {
	const { filters } = Context.Filters.useFiltersContext();

	return (
		<Styled.Sort>
			<Styled.SortTitle>SORT BY</Styled.SortTitle>
			<Styled.SortButtons>
				<Styled.SortButton
					selected={filters.state.sort.toLowerCase() === "oldest"}
					onClick={() => filters.setters.setSort("oldest")}
				>
					Oldest
				</Styled.SortButton>
				<Styled.SortButton
					selected={filters.state.sort.toLowerCase() === "newest"}
					onClick={() => filters.setters.setSort("newest")}
				>
					Newest
				</Styled.SortButton>
				<Styled.SortButton
					selected={filters.state.sort.toLowerCase() === "relevance"}
					onClick={() => filters.setters.setSort("relevance")}
				>
					Relevance
				</Styled.SortButton>
			</Styled.SortButtons>
		</Styled.Sort>
	);
};
