import styled from "styled-components";
import { animated } from "react-spring";

import * as Style from "~/style";
import * as Icons from "~/icons";

// ============== //
// ↓↓↓ Filter ↓↓↓ //
// ============== //

type Filter = { isOpen: boolean };

export const Filter = styled(animated.div).attrs(() => ({
	width: Style.Theme.bind("device", {
		ultrawide: Style.Snippets.px(375),
		desktop: Style.Snippets.px(375),
		tablet: Style.Snippets.px(375),
		mobile: "100%",
	}),
	backgroundColor: Style.Theme.bind("mode", {
		dark: Style.Constants.filter.dark.backgroundColor,
	}),
}))<Filter>`
	${Style.Snippets.flex("column", "auto", "auto")};
	${Style.Snippets.hideScrollbar()};
	position: fixed;
	right: 0;
	z-index: 4;
	width: ${(props) => props.width};
	height: 100vh;
	background-color: ${(props) => props.backgroundColor};
	overflow-y: auto;
`;

export const FilterContainer = styled("div").attrs(() => ({
	backgroundColor: Style.Theme.bind("mode", {
		dark: Style.Constants.filter.dark.backgroundColor,
	}),
}))`
	${Style.Snippets.flex("column", "auto", "auto")};
	${Style.Snippets.fill()};
	${Style.Snippets.hideScrollbar()};
	background-color: ${(props) => props.backgroundColor};
	overflow-y: auto;
`;

export const FilterCloseButton = styled(Icons.Close).attrs(() => ({
	color: Style.Theme.bind("mode", {
		dark: Style.Constants.filter.dark.closeButtonColor,
	}),
}))`
	${Style.Snippets.size(30)};
	position: absolute;
	top: 30px;
	right: 30px;
	color: ${(props) => props.color};
	cursor: pointer;
`;

export const FilterSubmitButton = styled("div").attrs(() => ({
	color: Style.Theme.bind("mode", {
		dark: Style.Constants.filter.dark.submitButtonColor,
	}),
	backgroundColor: Style.Theme.bind("mode", {
		dark: Style.Constants.filter.dark.submitButtonBackgroundColor,
	}),
}))`
	margin: 40px ${Style.Constants.layout.mobile.padding}px;
	padding: 15px 10px;
	color: ${(props) => props.color};
	background-color: ${(props) => props.backgroundColor};
	border-radius: 5px;
	text-align: center;
`;

// ========================= //
// ↓↓↓ Calendar Dropdown ↓↓↓ //
// ========================= //
export const FilterCalendarDropdown = styled("div").attrs(() => ({
	color: Style.Theme.bind("mode", {
		dark: Style.Constants.filter.dark.calendarDropdownColor,
	}),
	backgroundColor: Style.Theme.bind("mode", {
		dark: Style.Constants.filter.dark.calendarDropdownBackgroundColor,
	}),
}))`
	${Style.Snippets.flex("column", "auto", "auto")};
	${Style.Snippets.hideScrollbar()};
	position: fixed;
	right: 0;
	z-index: ${(props) => (props.isOpen ? 2 : -1)};
	padding: ${Style.Constants.layout.mobile.padding}px;
	width: ${(props) => (props.windowWidth < 375 ? `${props.windowWidth} px` : `${375}px`)};
	height: 100vh;
	color: ${(props) => props.color};
	background-color: ${(props) => props.backgroundColor};
	opacity: ${(props) => (props.isOpen ? 1 : 0)};
	overflow-y: auto;
`;

export const FilterCalendarDropdownClose = styled("div").attrs(() => ({
	color: Style.Theme.bind("mode", {
		dark: Style.Constants.filter.dark.calendarDropdownCloseColor,
	}),
}))`
	margin-bottom: 10px;
	width: 100%;
	color: ${(props) => props.color};
	font-size: 14px;
	text-align: right;
	cursor: pointer;
`;

export const FilterCalendarDropdownBody = styled("div")`
	${Style.Snippets.grid(2, "1fr", 0)};
	flex: 1;
`;

export const FilterCalendarDropdownBodyMonths = styled("div")``;

export const FilterCalendarDropdownBodyYears = styled("div")``;

export const FilterCalendarDropdownBodyOption = styled("div").attrs(() => ({
	backgroundColor: Style.Theme.bind("mode", {
		dark: Style.Constants.filter.dark.calendarDropdownBodyOptionActiveBackgroundColor,
	}),
}))`
	padding: 20px 0px;
	border-radius: 5px;
	font-size: 16px;
	text-align: center;
	transition: 0.2s;

	:active {
		background-color: ${(props) => props.backgroundColor};
	}
`;
