import * as React from "react";
import * as Use from "react-use";

import * as Context from "~/context";

import * as Styled from "./observer.styled";

type ObserverProps = React.PropsWithChildren<{}>

export const Observer = (props: ObserverProps) => {
	const { children } = props;

	// --- Mouse ---
	const mouseRef = React.useRef<HTMLDivElement>(null);
	const mouseCurrent = Use.useMouse(mouseRef);
	const { mouse } = Context.Mouse.useMouseContext();

	// --- Navigation ---
	const { navigation } = Context.Navigation.useNavigationContext();

	// --- Scroll ---
	const scrollRef = React.useRef<HTMLDivElement>(null);
	const scrollCurrent = Use.useScroll(scrollRef);
	const { scroll } = Context.Scroll.useScrollContext();

	// --- Location ---
	const locationCurrent = Use.useLocation();
	const { location } = Context.Location.useLocationContext();

	// --- Window Size ---
	const windowSizeCurrent = Use.useWindowSize();
	const { windowSize } = Context.WindowSize.useWindowSizeContext();

	// --- Theme ---
	const { theme } = Context.Theme.useThemeContext();

	// --- Mouse (Effect) ---
	React.useEffect(() => mouse.actions.setMouse(mouseCurrent), [mouseCurrent]);

	// --- Scroll (Effect) ---
	React.useEffect(() => scroll.actions.useEffectSetScroll(scrollCurrent), [
		scrollCurrent
	]);

	// --- Location (Effect) ---
	React.useEffect(() => location.actions.setLocation(locationCurrent), [locationCurrent]);

	// --- Window Size (Effect) ---
	React.useEffect(() => {
		windowSize.actions.setWindowSize(windowSizeCurrent);
		theme.effects.useEffectSetThemeDevice(windowSizeCurrent);
	}, [windowSizeCurrent]);

	return (
		<Styled.ObserverScrollContainer ref={scrollRef}>
			<Styled.ObserverMouseContainer ref={mouseRef}>
				{children}
			</Styled.ObserverMouseContainer>
		</Styled.ObserverScrollContainer>
	);
};
