import { graphql, useStaticQuery } from "gatsby";

export type SiteMetaData = {
  site: {
    siteMetadata: {
      title: string;
      author: string;
      description: string;
      lang: string;
    },
  },
}

export const useSiteMetadata = () => {
  const { site } = useStaticQuery<SiteMetaData>(
    graphql`
      query SiteMetaData {
        site {
          siteMetadata {
            title
            author
            description
          }
        }
      }
    `
  )

  return site.siteMetadata
}