import * as React from "react";
import * as Use from "react-use";

import * as Context from "~/context";

import * as Styled from "./date.styled";
import { MonthDays } from "./helpers/month-days";

export const Date = () => {
	const { filters } = Context.Filters.useFiltersContext();

	React.useEffect(() => {
		return filters.setters.setLeapYear();
	}, [filters.state.currentYear]);

	React.useEffect(() => {
		return filters.setters.fixOffsetLogic();
	}, [
		filters.state.startMonth,
		filters.state.startDay,
		filters.state.startYear,
		filters.state.endMonth,
		filters.state.endDay,
		filters.state.endYear,
	]);

	const { width } = Use.useWindowSize();

	const weekdays = filters.state.weekdays.map((weekday: Context.Filters.Weekday) => {
		return (
			<Styled.DateCalendarBodyWeekdaysDay key={weekday.id} windowWidth={width}>
				{weekday.day}
			</Styled.DateCalendarBodyWeekdaysDay>
		);
	});

	return (
		<Styled.DateContainer>
			<Styled.Date>
				<Styled.DateTitle>DATE RANGE</Styled.DateTitle>

				<Styled.DateCalendar>
					<Styled.DateCalendarHeader windowWidth={width}>
						<Styled.DateCalenderHeaderMonthYear
							onClick={filters.setters.toggleDateDropdown}
						>
							{filters.state.currentMonth} {filters.state.currentYear}
							<Styled.DateCalenderHeaderDropdownArrow />
						</Styled.DateCalenderHeaderMonthYear>
						<Styled.DateCalendarHeaderArrows>
							<Styled.DateCalendarHeaderArrowsLeft
								onClick={filters.setters.setMonthPrev}
							/>
							<Styled.DateCalendarHeaderArrowsRight
								onClick={filters.setters.setMonthNext}
							/>
						</Styled.DateCalendarHeaderArrows>
					</Styled.DateCalendarHeader>
					<Styled.DateCalendarBody>
						<Styled.DateCalendarBodyWeekdays windowWidth={width}>{weekdays}</Styled.DateCalendarBodyWeekdays>
						<Styled.DateCalendarBodyDays>
							<MonthDays />
						</Styled.DateCalendarBodyDays>
					</Styled.DateCalendarBody>
				</Styled.DateCalendar>

				<Styled.DateRange>
					<Styled.DateRangeStart>
						{filters.variables.months[filters.state.startMonth].slice(0, 3)}{" "}
						{filters.state.startDay}, {filters.state.startYear}
					</Styled.DateRangeStart>
					<Styled.DateRangeArrow />
					<Styled.DateRangeEnd>
						{filters.variables.months[filters.state.endMonth].slice(0, 3)}{" "}
						{filters.state.endDay}, {filters.state.endYear}
					</Styled.DateRangeEnd>
				</Styled.DateRange>
			</Styled.Date>
		</Styled.DateContainer>
	);
};
