import * as React from "react";

import * as Styled from "./footer.styled";

export const Footer = () => {
	return (
		<Styled.Footer>
			<Styled.FooterBackToTopButton to="home-header" smooth={true} duration={200}>
				Back To Top
			</Styled.FooterBackToTopButton>

			<Styled.FooterNavContainer>
				<Styled.FooterNav>
					<Styled.FooterNavTitle>Navigation</Styled.FooterNavTitle>
					<Styled.FooterNavLinks>
						<Styled.FooterNavLocalLink to="/">Home</Styled.FooterNavLocalLink>
						<Styled.FooterNavLocalLink to="/">Report A Bug</Styled.FooterNavLocalLink>
						<Styled.FooterNavLocalLink to="/">Tutorial</Styled.FooterNavLocalLink>
						<Styled.FooterNavExternalLink href="#">
							Main Site
						</Styled.FooterNavExternalLink>
					</Styled.FooterNavLinks>
				</Styled.FooterNav>

				<Styled.FooterNav>
					<Styled.FooterNavTitle>Resources</Styled.FooterNavTitle>
					<Styled.FooterNavLinks>
						<Styled.FooterNavExternalLink href="#">
							Copyright
						</Styled.FooterNavExternalLink>
						<Styled.FooterNavExternalLink href="#">Privacy</Styled.FooterNavExternalLink>
						<Styled.FooterNavExternalLink href="#">Terms</Styled.FooterNavExternalLink>
						<Styled.FooterNavExternalLink href="#">Contact</Styled.FooterNavExternalLink>
					</Styled.FooterNavLinks>
				</Styled.FooterNav>
			</Styled.FooterNavContainer>

			<Styled.FooterSocials>
				<Styled.FooterSocialsLink href="https://www.facebook.com/gary">
					<Styled.FooterSocialsFacebook />
				</Styled.FooterSocialsLink>

				<Styled.FooterSocialsLink href="https://www.instagram.com/garyvee/">
					<Styled.FooterSocialsInstagram />
				</Styled.FooterSocialsLink>

				<Styled.FooterSocialsLink href="https://twitter.com/garyvee">
					<Styled.FooterSocialsTwitter />
				</Styled.FooterSocialsLink>

				<Styled.FooterSocialsLink href="https://www.youtube.com/user/GaryVaynerchuk">
					<Styled.FooterSocialsYouTube />
				</Styled.FooterSocialsLink>
			</Styled.FooterSocials>
		</Styled.Footer>
	);
};
