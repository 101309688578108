import * as React from "react";
import moment from "moment";

import * as Context from "~/context";

import * as Styled from "../date.styled";

type Props = {
  width: number;
  currentMonthDays: number[];
	currentMonth: number;
	startDate: moment.Moment;
	endDate: moment.Moment;
};

export const CurrentMonthDays = (props: Props) => {
	const { width, currentMonthDays, currentMonth, startDate, endDate } = props;
	const { filters } = Context.Filters.useFiltersContext();

	const component = currentMonthDays.map((day: number, index: number) => {
		return (
			<Styled.DateCalendarBodyDaysDay
				key={`current-${index}`}
				onClick={() =>
					filters.setters.setRange(currentMonth, day, filters.state.currentYear)
				}
				inCurrentMonth={true}
				startDate={startDate}
				endDate={endDate}
				date={moment([filters.state.currentYear, currentMonth, day])}
				windowWidth={width}
			>
				{day}
			</Styled.DateCalendarBodyDaysDay>
		);
	});

	return <>{component}</>;
};
