import * as React from "react";

import * as Styled from "./header.styled";
import * as Components from "~/components";

export const Header = () => {
	return (
		<Styled.Header>
			<Components.Navbar noPadding={true} />

			<Styled.HeaderTitle>Search anything I've ever said.</Styled.HeaderTitle>

			<Styled.HeaderSubtitle>
				We’ve indexed over 14,571 hours of content, so you can find the perfect moment.
			</Styled.HeaderSubtitle>

			<Styled.HeaderSearch to="/search">
				<Styled.HeaderSearchIcon />
				<Styled.HeaderSearchPlaceholder>Search</Styled.HeaderSearchPlaceholder>
			</Styled.HeaderSearch>
		</Styled.Header>
	);
};
