import * as React from "react";

import * as Templates from "~/templates";
import * as Components from "~/components";

export const NotFound = () => (
	<Templates.Layout>
		<Components.SEO title="404: Not found" />
		<h1>NOT FOUND</h1>
		<p>You just hit a route that doesn&#39;t exist... the sadness.</p>
	</Templates.Layout>
);

