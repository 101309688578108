import styled from "styled-components";
import { animated } from "react-spring";

import * as Style from "~/style";
import * as SVG from "~/components/svgs";

// ====================== //
// ↓↓↓ Loading Screen ↓↓↓ //
// ====================== //

type Loading = {
	loaded: boolean;
};

export const LoadingScreen = styled(animated.div).attrs(() => ({
	backgroundColor: Style.Theme.bind("mode", {
		dark: Style.Constants.loading.dark.backgroundColor,
	}),
}))<Loading>`
	${Style.Snippets.fixed("0px", "0px", "0px", "0px")};
	${Style.Snippets.flex()};
	${Style.Snippets.size("100vh", "100vw")};
	z-index: 5;
	background-color: ${(props) => props.backgroundColor};
`;

export const LoadingScreenIconContainer = styled(animated.div)`
	position: absolute;
	z-index: 5;
`;

export const LoadingScreenIcon = styled(SVG.Logo)``;
