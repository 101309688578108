import * as React from "react";

import * as Context from "~/context";

import * as Styled from "./handpicked.styled";

export const Handpicked = () => {
	const { home } = Context.Home.useHomeContext();

	return (
		<Styled.HandpickedContainer>
			<Styled.HandpickedTitle>Handpicked</Styled.HandpickedTitle>
			<HandpickedCards />
		</Styled.HandpickedContainer>
	);
};

const HandpickedCards = () => {
	const { home } = Context.Home.useHomeContext();

	const topics = home.state.topics;

	return (
		<Styled.HandpickedCardsContainer totalCards={topics.length}>{
			topics.map((topic: Context.Home.Topic) => {
				return (
					<Styled.HandpickedCardsItem
						onClick={() => home.setters.openModalAndSetCurrentTopic(topic)}
						key={topic.id}
					>
						{topic.content}
					</Styled.HandpickedCardsItem>
				);
			})
		}</Styled.HandpickedCardsContainer>
	)
}

