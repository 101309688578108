import styled from "styled-components";
import * as Gatsby from "gatsby";

import * as Style from "~/style";
import * as Icons from "~/icons";
import * as SVG from "~/components/svgs";

// ============== //
// ↓↓↓ Header ↓↓↓ //
// ============== //

export const Header = styled("div").attrs((props) => ({
	id: "home-header",
	padding: Style.Theme.bind("device", {
		ultrawide: Style.Snippets.px(Style.Constants.layout.ultrawide.padding),
		desktop: Style.Snippets.px(Style.Constants.layout.desktop.padding),
		tablet: Style.Snippets.px(Style.Constants.layout.tablet.padding),
		mobile: Style.Snippets.px(Style.Constants.layout.mobile.padding),
	}),
	backgroundColor: Style.Colors.TRANSPARENT,
}))`
	position: absolute;
	flex: 1;
	width: 100%;
	padding-top: 0px;
	padding-right: ${(props) => props.padding};
	padding-bottom: 40px;
	padding-left: ${(props) => props.padding};
	background-color: ${(props) => props.backgroundColor};
	transform: translateY(1px);
`;

export const HeaderTitle = styled("h1").attrs(() => ({
	color: Style.Theme.bind("mode", {
		dark: Style.Constants.home.dark.header.titleColor,
	}),
}))`
	margin-bottom: 20px;
	color: ${(props) => props.color};
	font-size: 30px;
	font-weight: 700;
`;

export const HeaderSubtitle = styled("h2").attrs(() => ({
	color: Style.Theme.bind("mode", {
		dark: Style.Constants.home.dark.header.subtitleColor,
	}),
}))`
	color: ${(props) => props.color};
	font-size: 14px;
	line-height: 150%;
`;

// ============== //
// ↓↓↓ Search ↓↓↓ //
// ============== //

export const HeaderSearch = styled(Gatsby.Link).attrs(() => ({
	backgroundColor: Style.Theme.bind("mode", {
		dark: Style.Constants.home.dark.header.searchBackgroundColor,
	}),
}))`
	${Style.Snippets.flex("row", "auto", "center")};
	${Style.Snippets.size(`${Style.Constants.search.mobile.height}px`, "100%")};
	margin-top: 50px;
	background-color: ${(props) => props.backgroundColor};
	border-radius: ${Style.Constants.search.mobile.borderRadius}px;
	text-decoration: none;
`;

export const HeaderSearchIcon = styled(Icons.Search).attrs(() => ({
	color: Style.Theme.bind("mode", {
		dark: Style.Constants.home.dark.header.searchIconColor,
	}),
}))`
	${Style.Snippets.size(`${Style.Constants.search.mobile.icon}px`, "auto")};
	margin: 0px 15px 0px 20px;
	color: ${(props) => props.color};
`;

export const HeaderSearchPlaceholder = styled("div").attrs(() => ({
	color: Style.Theme.bind("mode", {
		dark: Style.Constants.home.dark.header.searchPlaceholderColor,
	}),
}))`
	color: ${(props) => props.color};
	font-size: 14px;
	outline: none;
`;
