import styled from "styled-components";
import { animated } from "react-spring";

import * as Style from "~/style";

// ======================== //
// ↓↓↓ Handpicked Modal ↓↓↓ //
// ======================== //

export const HandpickedModalContainer = styled(animated.div)`
	${Style.Snippets.fixed("0px", "0px", "0px", "0px")};
	z-index: 2;
`;

type Overlay = { open: boolean };

export const HandpickedModalOverlay = styled("div").attrs(() => ({
	backgroundColor: Style.Theme.bind("mode", {
		dark: Style.Constants.home.dark.cardModalOverlayBackgroundColor,
	}),
}))<Overlay>`
	${Style.Snippets.fill()};
	position: absolute;
	z-index: 1;
	display: ${(props) => (props.open ? "block" : "none")};
	background-color: ${(props) => props.backgroundColor};
`;

type HandpickedModalContentContainer = {
	height: number;
	width: number;
};

export const HandpickedModalContentContainer = styled("div").attrs(
	(props: HandpickedModalContentContainer) => ({
		backgroundColor: Style.Theme.bind("mode", {
			dark: Style.Constants.home.dark.handpicked.backgroundColor,
		}),
		height: props.height,
		width: props.width,
	})
)`
	${Style.Snippets.center()};
	position: absolute;
	z-index: 3;
	width: ${(props) => `${props.width - 50}px`};
	padding: 30px;
	background-color: ${(props) => props.backgroundColor};
	border-radius: 10px;
`;

export const HandpickedModalContentTitle = styled("div").attrs(() => ({
	color: Style.Theme.bind("mode", {
		dark: Style.Constants.home.dark.handpicked.titleColor,
	}),
}))`
	margin-bottom: 20px;
	color: ${(props) => props.color};
	font-size: 18px;
	font-weight: 700;
	line-height: 150%;
`;

// export const HandpickedModalContentWrapper = styled(animated.div)`
// 	${Style.Snippets.center()};
// 	position: fixed;
// 	z-index: 2;
// `;
