import * as React from "react";
import styled, { css } from "styled-components";

import * as Style from "~/style";

export const ObserverScrollContainer = styled("div")`
	${Style.Snippets.fill()}
	${Style.Snippets.absolute(0, 0, 0, 0)}
  overflow: auto;
`;

export const ObserverMouseContainer = styled("div")`
	${Style.Snippets.size("auto", "auto")}
`;
