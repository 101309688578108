import styled from "styled-components";
import * as Gatsby from "gatsby";

import * as Style from "~/style";
import * as SVG from "~/components/svgs";

// ================== //
// ↓↓↓ Navbar ↓↓↓ //
// ================== //

type Navbar = {
	noPadding?: boolean;
	noDisplay?: boolean;
};

export const Navbar = styled("div").attrs(() => ({
	display: Style.Theme.bind("device", {
		ultrawide: "block",
		desktop: "block",
		tablet: "block",
		mobile: "block",
	}),
	margin: Style.Theme.bind("device", {
		ultrawide: Style.Snippets.px(Style.Constants.layout.ultrawide.padding),
		desktop: Style.Snippets.px(Style.Constants.layout.desktop.padding),
		tablet: Style.Snippets.px(Style.Constants.layout.tablet.padding),
		mobile: Style.Snippets.px(Style.Constants.layout.mobile.padding),
	}),
	padding: Style.Theme.bind("device", {
		ultrawide: Style.Snippets.px(Style.Constants.layout.ultrawide.padding),
		desktop: Style.Snippets.px(Style.Constants.layout.desktop.padding),
		tablet: Style.Snippets.px(Style.Constants.layout.tablet.padding),
		mobile: Style.Snippets.px(Style.Constants.layout.mobile.padding),
	}),
}))<Navbar>`
	${Style.Snippets.size("60px", "100%")};
	display: ${(props) => (props.noDisplay ? "none" : "block")};
	margin: 40px 0px;
	padding: ${(props) => (props.noPadding ? "0px" : props.padding)};
	padding-top: 0px;
	padding-bottom: 0px;
`;

export const NavbarLogoLink = styled(Gatsby.Link)`
	display: block;
	width: 60px;
`;

export const NavbarLogo = styled(SVG.Logo)``;
