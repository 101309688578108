import styled, { css } from "styled-components";
import { animated } from "react-spring";
import * as Gatsby from "gatsby";

import * as Style from "~/style";
import * as Icons from "~/icons";

// ============== //
// ↓↓↓ Search ↓↓↓ //
// ============== //

export const Search = styled(animated.form).attrs(() => ({
	backgroundColor: Style.Theme.bind("mode", {
		dark: Style.Constants.search.dark.backgroundColor,
	}),
}))`
	${Style.Snippets.flex("column", "auto", "auto")};
	${Style.Snippets.size("100vh", "100vw")};
	background-color: ${(props) => props.backgroundColor};
`;

// =========== //
// ↓↓↓ Bar ↓↓↓ //
// =========== //

type SearchBar = { searching: boolean };

export const SearchBar = styled("input").attrs(() => ({
	id: "search-bar",
	colorSearching: Style.Theme.bind("mode", {
		dark: Style.Constants.search.dark.barColorSearching,
	}),
	colorNotSearching: Style.Theme.bind("mode", {
		dark: Style.Constants.search.dark.barColorNotSearching,
	}),
	backgroundColor: Style.Theme.bind("mode", {
		dark: Style.Constants.search.dark.barBackgroundColor,
	}),
}))<SearchBar>`
	${Style.Snippets.size("100px", "100%")};
	color: ${(props) => (props.searching ? props.colorSearching : props.colorNotSearching)};
	background-color: ${(props) => props.backgroundColor};
	font-size: 30px;
	font-weight: 700;
	text-align: center;

	outline: none;
`;

export const SearchBody = styled("div")`
	position: relative;
	flex: 1;
`;

// =============== //
// ↓↓↓ Results ↓↓↓ //
// =============== //

export const SearchResults = styled(animated.div).attrs(() => ({
	paddingSides: Style.Theme.bind("device", {
		ultrawide: Style.Snippets.px(Style.Constants.layout.ultrawide.padding),
		desktop: Style.Snippets.px(Style.Constants.layout.desktop.padding),
		tablet: Style.Snippets.px(Style.Constants.layout.tablet.padding),
		mobile: Style.Snippets.px(Style.Constants.layout.mobile.padding),
	}),
	paddingBottom: Style.Theme.bind("device", {
		ultrawide: Style.Snippets.px(30),
		desktop: Style.Snippets.px(30),
		tablet: Style.Snippets.px(20),
		mobile: Style.Snippets.px(10),
	}),
	backgroundColor: Style.Theme.bind("mode", {
		dark: Style.Constants.search.dark.resultsBackgroundColor,
	}),
}))`
	${Style.Snippets.fill()};
	${Style.Snippets.hideScrollbar()};
	position: absolute;
	/* padding: 20px ${Style.Constants.layout.mobile.padding}px 10px; */
	padding: ${(props) => props.paddingSides};
	padding-top: 0px;
	padding-bottom: ${(props) => props.paddingBottom};
	background-color: ${(props) => props.backgroundColor};
	overflow: auto;
`;

export const SearchResultsHeader = styled("div").attrs(() => ({
	margin: Style.Theme.bind("device", {
		ultrawide: Style.Snippets.px(40),
		desktop: Style.Snippets.px(40),
		tablet: Style.Snippets.px(30),
		mobile: Style.Snippets.px(20),
	}),
}))`
	${Style.Snippets.flex("row", "space-between", "center")};
	margin: ${(props) => props.margin};
	margin-right: 0px;
	margin-left: 0px;
`;

export const SearchResultsHeaderCountFilterContainer = styled("div")``;

export const SearchResultsHeaderCount = styled("div").attrs(() => ({
	color: Style.Theme.bind("mode", {
		dark: Style.Constants.search.dark.resultsHeaderCount,
	}),
}))`
	margin-bottom: 10px;
	color: ${(props) => props.color};
	font-size: 16px;
	font-weight: 700;
`;

export const SearchResultsHeaderFilter = styled("div").attrs(() => ({
	color: Style.Theme.bind("mode", {
		dark: Style.Constants.search.dark.resultsHeaderFilter,
	}),
}))`
	color: ${(props) => props.color};
	font-size: 12px;
`;

// ===================== //
// ↓↓↓ Header Button ↓↓↓ //
// ===================== //

export const SearchResultsHeaderButtonContainer = styled("div")`
	${Style.Snippets.grid(2, "1fr", 10, "center", "center")};
`;

const buttonStyles = css`
	${Style.Snippets.flex()};
	${Style.Snippets.square("33px")};
	border-radius: 6px;
	text-decoration: none;
`;

export const SearchResultsHeaderHomeButton = styled(Gatsby.Link).attrs(() => ({
	color: Style.Theme.bind("mode", {
		dark: Style.Constants.search.dark.buttonColor,
	}),
	backgroundColor: Style.Theme.bind("mode", {
		dark: Style.Constants.search.dark.buttonBackgroundColor,
	}),
	boxShadow: Style.Theme.bind("mode", {
		dark: Style.Constants.search.dark.buttonShadow,
	}),
}))`
	${buttonStyles};
	color: ${(props) => props.color};
	background-color: ${(props) => props.backgroundColor};
	box-shadow: ${(props) => props.boxShadow};
	transition: 0.1s ease-in-out;

	:hover {
		opacity: 0.7;
	}
`;

export const SearchResultsHeaderFilterButton = styled("div").attrs(() => ({
	color: Style.Theme.bind("mode", {
		dark: Style.Constants.search.dark.buttonColor,
	}),
	backgroundColor: Style.Theme.bind("mode", {
		dark: Style.Constants.search.dark.buttonBackgroundColor,
	}),
	boxShadow: Style.Theme.bind("mode", {
		dark: Style.Constants.search.dark.buttonShadow,
	}),
}))`
	${buttonStyles};
	color: ${(props) => props.color};
	background-color: ${(props) => props.backgroundColor};
	box-shadow: ${(props) => props.boxShadow};
	transition: 0.1s ease-in-out;
	cursor: pointer;

	:hover {
		opacity: 0.7;
	}
`;

export const SearchResultsHeaderHomeIcon = styled(Icons.Home)`
	${Style.Snippets.square("18px")}
`;

export const SearchResultsHeaderFilterIcon = styled(Icons.Tune)`
	${Style.Snippets.square("18px")}
	transform: rotate(90deg);
`;

// ====================== //
// ↓↓↓ Search Overlay ↓↓↓ //
// ====================== //

type Overlay = { isOpen: boolean };

export const SearchOverlay = styled("div").attrs(() => ({
	backgroundColor: Style.Theme.bind("mode", {
		dark: Style.Constants.search.dark.filterOverlayBackgroundColor,
	}),
}))<Overlay>`
	${Style.Snippets.position("fixed", 0, 0, 0, 0)};
	z-index: ${(props) => (props.isOpen ? 1 : -1)};
	background-color: ${(props) => props.backgroundColor};
	opacity: ${(props) => (props.isOpen ? 1 : 0)};
`;
