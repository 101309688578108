import * as React from "react";
import * as Use from "react-use";

import * as Context from "~/context";
import * as Templates from "~/templates";

import * as Styled from "./filter.styled";
import * as Springs from "./filter.springs";
import * as Parts from "./lib";

export const Filter = () => {
	const { filters } = Context.Filters.useFiltersContext();
	const { search } = Context.Search.useSearchContext();

	const animateFilter = Springs.enter(search.state.filterModal);

	return (
		<Styled.Filter style={animateFilter}>
			<Styled.FilterCloseButton onClick={() => search.setters.setFilterModal(false)} />
			<FilterCalendarDropdown />
			<Parts.Date />
			<Parts.Sort />
			{/* <Parts.Platforms /> */}
			<Styled.FilterSubmitButton onClick={filters.handlers.handleSubmit}>
				Submit
			</Styled.FilterSubmitButton>
		</Styled.Filter>
	);
};

// ================================ //
// ↓↓↓ Filter Calendar Dropdown ↓↓↓ //
// ================================ //

const FilterCalendarDropdown = () => {
	const { filters } = Context.Filters.useFiltersContext();

	const { width, height } = Use.useWindowSize();

	return (
		<React.Fragment>
			{/* <Styled.FilterCalendarOverlay
				onClick={filters.setters.toggleDateDropdown}
				isOpen={filters.state.dateDropdown}
			/> */}
			<Styled.FilterCalendarDropdown
				isOpen={filters.state.dateDropdown}
				windowWidth={width}
				windowHeight={height}
			>
				<Styled.FilterCalendarDropdownClose onClick={filters.setters.toggleDateDropdown}>
					Close
				</Styled.FilterCalendarDropdownClose>

				<Styled.FilterCalendarDropdownBody>
					<DropdownMonths />
					<DropdownYears />
				</Styled.FilterCalendarDropdownBody>
			</Styled.FilterCalendarDropdown>
		</React.Fragment>
	);
};

// ======================= //
// ↓↓↓ Dropdown Months ↓↓↓ //
// ======================= //

const DropdownMonths = () => {
	const { filters } = Context.Filters.useFiltersContext();

	const months = filters.state.months.map((month: Context.Filters.Month) => {
		return (
			<Styled.FilterCalendarDropdownBodyOption
				onClick={() => filters.setters.setMonth(month.name)}
				key={month.id}
			>
				{month.name}
			</Styled.FilterCalendarDropdownBodyOption>
		);
	});

	return (
		<Styled.FilterCalendarDropdownBodyMonths>
			{months}
		</Styled.FilterCalendarDropdownBodyMonths>
	);
};

// ====================== //
// ↓↓↓ Dropdown Years ↓↓↓ //
// ====================== //

const DropdownYears = () => {
	const { filters } = Context.Filters.useFiltersContext();

	const years = filters.state.years.map((year: Context.Filters.Year) => {
		return (
			<Styled.FilterCalendarDropdownBodyOption
				onClick={() => filters.setters.setYear(year.number)}
				key={year.id}
			>
				{year.number}
			</Styled.FilterCalendarDropdownBodyOption>
		);
	});

	return (
		<Styled.FilterCalendarDropdownBodyYears>
			{years}
		</Styled.FilterCalendarDropdownBodyYears>
	);
};
