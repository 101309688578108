import styled from "styled-components";
import { animated } from "react-spring";
import * as Gatsby from "gatsby";

import * as Style from "~/style";
import * as Icons from "~/icons";

// ==================== //
// ↓↓↓ Result Cards ↓↓↓ //
// ==================== //

export const ResultsCards = styled("div").attrs(() => ({
	gridGap: Style.Theme.bind("device", {
		ultrawide: Style.Snippets.px(30),
		desktop: Style.Snippets.px(30),
		tablet: Style.Snippets.px(20),
		mobile: Style.Snippets.px(10),
	}),
}))`
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
	grid-gap: ${(props) => props.gridGap};
	padding-bottom: 40px;
	width: 100%;
`;

export const ResultsCardsCardContainer = styled(animated.div)``;

export const ResultsCardsCard = styled(Gatsby.Link).attrs(() => ({
	to: "/search/result",
	flexDirection: Style.Theme.bind("device", {
		ultrawide: "column",
		desktop: "column",
		tablet: "column",
		mobile: "row",
	}),
	padding: Style.Theme.bind("device", {
		ultrawide: Style.Snippets.px(20),
		desktop: Style.Snippets.px(20),
		tablet: Style.Snippets.px(20),
		mobile: Style.Snippets.px(10),
	}),
	minHeight: Style.Theme.bind("device", {
		ultrawide: Style.Snippets.px(400),
		desktop: Style.Snippets.px(400),
		tablet: Style.Snippets.px(350),
		mobile: "auto",
	}),
	backgroundColor: Style.Theme.bind("mode", {
		dark: Style.Constants.search.dark.resultCards.backgroundColor,
	}),
	boxShadow: Style.Theme.bind("mode", {
		dark: Style.Constants.search.dark.resultCards.shadow,
	}),
}))`
	display: flex;
	flex-direction: ${(props) => props.flexDirection};
	padding: ${(props) => props.padding};
	/* width: 100%; */
	/* min-height: ${(props) => props.minHeight}; */
	background-color: ${(props) => props.backgroundColor};
	border-radius: 10px;
	text-decoration: none;
	box-shadow: ${(props) => props.boxShadow};
	transition: 0.1s ease-in-out;

	:hover {
		opacity: 0.6;
	}
`;

// ====================== //
// ↓↓↓ Card Thumbnail ↓↓↓ //
// ====================== //

export const ResultsCardsCardThumbnailContainer = styled("div").attrs(() => ({
	width: Style.Theme.bind("device", {
		ultrawide: "100%",
		desktop: "100%",
		tablet: "100%",
		mobile: Style.Snippets.px(80),
	}),
	height: Style.Theme.bind("device", {
		ultrawide: "auto",
		desktop: "auto",
		tablet: "auto",
		mobile: Style.Snippets.px(80),
	}),
	marginBottom: Style.Theme.bind("device", {
		ultrawide: Style.Snippets.px(20),
		desktop: Style.Snippets.px(20),
		tablet: Style.Snippets.px(20),
		mobile: Style.Snippets.px(0),
	}),
	backgroundColor: Style.Theme.bind("mode", {
		dark: Style.Constants.search.dark.resultCards.thumbnailContainer,
	}),
	boxShadow: Style.Theme.bind("mode", {
		dark: Style.Constants.search.dark.resultCards.thumbnailContainerShadow,
	}),
}))`
	position: relative;
	flex-shrink: 0;
	width: ${(props) => props.width};
	height: ${(props) => props.height};
	margin-right: 10px;
	margin-bottom: ${(props) => props.marginBottom};
	background-color: ${(props) => props.backgroundColor};
	border-radius: 8px;
	box-shadow: ${(props) => props.boxShadow};
`;

export const ResultsCardsCardThumbnail = styled("img")`
	${Style.Snippets.fill()};
	/* position: absolute; */
	border-radius: 8.5px;
	opacity: 0.5;
`;

export const ResultsCardsCardThumbnailPlatformIcon = styled("div").attrs(() => ({
	width: Style.Theme.bind("device", {
		ultrawide: Style.Snippets.px(40),
		desktop: Style.Snippets.px(40),
		tablet: Style.Snippets.px(40),
		mobile: Style.Snippets.px(26),
	}),
	backgroundColor: Style.Theme.bind("mode", {
		dark: Style.Constants.search.dark.resultCards.iconBackgroundColor,
	}),
}))`
	${Style.Snippets.flex()};
	position: absolute;
	right: 0;
	bottom: 0px;
	width: ${(props) => props.width};
	height: ${(props) => props.width};
	background-color: ${(props) => props.backgroundColor};
	border-radius: 8px 0px 8px 0px;
`;

export const YouTubeIcon = styled(Icons.Youtube).attrs(() => ({
	width: Style.Theme.bind("device", {
		ultrawide: Style.Snippets.px(26),
		desktop: Style.Snippets.px(26),
		tablet: Style.Snippets.px(26),
		mobile: Style.Snippets.px(18),
	}),
}))`
	width: ${(props) => props.width};
	height: ${(props) => props.width};
	color: ${Style.Constants.globalColors.socials.muted};
`;

export const InstagramIcon = styled(Icons.Instagram).attrs(() => ({
	width: Style.Theme.bind("device", {
		ultrawide: Style.Snippets.px(26),
		desktop: Style.Snippets.px(26),
		tablet: Style.Snippets.px(26),
		mobile: Style.Snippets.px(18),
	}),
}))`
	width: ${(props) => props.width};
	height: ${(props) => props.width};
	color: ${Style.Constants.globalColors.socials.muted};
`;

export const TwitterIcon = styled(Icons.Twitter).attrs(() => ({
	width: Style.Theme.bind("device", {
		ultrawide: Style.Snippets.px(26),
		desktop: Style.Snippets.px(26),
		tablet: Style.Snippets.px(26),
		mobile: Style.Snippets.px(18),
	}),
}))`
	width: ${(props) => props.width};
	height: ${(props) => props.width};
	color: ${Style.Constants.globalColors.socials.muted};
`;

// ================= //
// ↓↓↓ Card Data ↓↓↓ //
// ================= //

export const ResultsCardsCardData = styled("div")`
	${Style.Snippets.flex("column", "space-between", "auto")};
	flex: 1;
	width: 100%;
`;

export const ResultsCardsCardDataTranscript = styled("div").attrs(() => ({
	color: Style.Theme.bind("mode", {
		dark: Style.Constants.search.dark.resultCards.cardDataTranscriptColor,
	}),
	marginBottom: Style.Theme.bind("device", {
		ultrawide: Style.Snippets.px(20),
		desktop: Style.Snippets.px(20),
		tablet: Style.Snippets.px(20),
		mobile: Style.Snippets.px(0),
	}),
}))`
	color: ${(props) => props.color};
	font-size: 12px;
	font-weight: bold;
	line-height: 150%;
	margin-bottom: ${(props) => props.marginBottom};
`;

export const ResultsCardsCardDataDate = styled("div").attrs(() => ({
	color: Style.Theme.bind("mode", {
		dark: Style.Constants.search.dark.resultCards.cardDataDateColor,
	}),
}))`
	align-self: flex-end;
	color: ${(props) => props.color};
	font-size: 0.5rem;
`;
