import styled from "styled-components";

import * as Style from "~/style";
import * as Icons from "~/icons";

// ================= //
// ↓↓↓ Platforms ↓↓↓ //
// ================= //

export const Platforms = styled("div")`
	padding: 40px 0px;
	flex: 1;
`;

export const PlatformsTitle = styled("div").attrs(() => ({
	color: Style.Theme.bind("mode", {
		dark: Style.Constants.filter.dark.platform.titleColor,
	}),
}))`
	padding: 0px ${Style.Constants.layout.mobile.padding}px;
	margin-bottom: 20px;
	color: ${(props) => props.color};
	font-size: 14px;
	font-weight: 700;
`;

// ============== //
// ↓↓↓ Button ↓↓↓ //
// ============== //

export const PlatformsButtonsContainer = styled("div")`
	${Style.Snippets.hideScrollbar()};
	padding: 0px ${Style.Constants.layout.mobile.padding}px;
	height: 100%;
	overflow-x: auto;
`;

export const PlatformsButtons = styled("div")`
	${Style.Snippets.flex("row", "auto", "center")};
	flex-shrink: 0;
	width: ${100 * 4 + 20 * 4}px;
	height: 100%;
	overflow-x: auto;
`;

type PlatformsButton = { available: boolean };

export const PlatformsButton = styled("button").attrs(() => ({
	backgroundColor: Style.Theme.bind("mode", {
		dark: Style.Constants.filter.dark.platform.buttonBackgroundColor,
	}),
	border: Style.Theme.bind("mode", {
		dark: `${Style.Constants.filter.dark.platform.buttonBorderColor} solid 2px`,
	}),
	boxShadow: Style.Theme.bind("mode", {
		dark: Style.Constants.filter.dark.platform.buttonShadow,
	}),
}))<PlatformsButton>`
	${Style.Snippets.flex()};
	${Style.Snippets.square("100px")};
	margin-right: 20px;
	background-color: ${(props) => props.backgroundColor};
	border: ${(props) => props.border};
	border-radius: 10px;
	box-shadow: ${(props) => props.boxShadow};
	opacity: ${(props) => (props.available ? 1 : 0.3)};
	outline: none;
`;

export const YouTubeIcon = styled(Icons.Youtube)`
	${Style.Snippets.square("50px")};
	color: ${Style.Constants.globalColors.socials.muted};
`;

export const InstagramIcon = styled(Icons.Instagram)`
	${Style.Snippets.square("50px")};
	color: ${Style.Constants.globalColors.socials.muted};
`;

export const TwitterIcon = styled(Icons.Twitter)`
	${Style.Snippets.square("50px")};
	color: ${Style.Constants.globalColors.socials.muted};
`;

export const TikTokIcon = styled(Icons.Twitter)`
	${Style.Snippets.square("50px")};
	color: ${Style.Constants.globalColors.socials.muted};
`;
