import * as Spring from "react-spring";

export function screen(loaded: boolean) {
	return Spring.useSpring({
		from: { transform: "translateY(0%)" },
		to: { transform: loaded ? "translateY(100%)" : "translateY(0%)" }
	});
}

export function icon(spin: boolean, toggleSpin: Function, loaded: boolean) {
	return Spring.useSpring({
		from: { transform: "rotateY(0deg)" },
		to: { transform: spin ? "rotateY(180deg)" : "rotateY(360deg)" },
		onRest: () => (loaded ? null : toggleSpin())
	});
}
