import * as React from "react";
import * as Use from "react-use";
import { Player, BigPlayButton } from "video-react";

import * as Context from "~/context";

import * as Styled from "./handpicked-modal.styled";
import * as Springs from "./handpicked-modal.springs";

export const HandpickedModal = () => {
	const { home } = Context.Home.useHomeContext();

	return (
		<React.Fragment>
			{Springs.cardModal(home.state.modalOpen).map(({ item, props, key }) => {
				return (
					item && (
						<Styled.HandpickedModalContainer key={key} style={props}>
							<HandpickedModalContent />
							<Styled.HandpickedModalOverlay
								onClick={home.setters.closeModal}
								open={home.state.modalOpen}
							/>
						</Styled.HandpickedModalContainer>
					)
				);
			})}
		</React.Fragment>
	);
};

const HandpickedModalContent = (props: Props) => {
	const { home } = Context.Home.useHomeContext();
	const { windowSize } = Context.WindowSize.useWindowSizeContext();

	return (
		<Styled.HandpickedModalContentContainer
			width={windowSize.getWindowWidth()}
			height={windowSize.getWindowHeight()}
		>
			<Styled.HandpickedModalContentTitle>
				{home.state.currentTopic.content}
			</Styled.HandpickedModalContentTitle>
			<HandpickedModalContentPlayer />
		</Styled.HandpickedModalContentContainer>
	);
};

const HandpickedModalContentPlayer = () => {
	const { home } = Context.Home.useHomeContext();

	return (
		<Player
			autoplay={true}
			poster={home.state.currentTopic.thumbnail}
			src={home.state.currentTopic.link}
		>
			<BigPlayButton position="center" />
		</Player>
	);
};
