import * as React from "react";
import * as Use from "react-use";
import moment from "moment";

import * as Context from "~/context";

import { PreviousMonthDays } from "./_previous-month-days";
import { CurrentMonthDays } from "./_current-month-days";
import { NextMonthDays } from "./_next-month-days";

export const MonthDays = () => {
	const { filters } = Context.Filters.useFiltersContext();

	const { width } = Use.useWindowSize();

	const previousMonth = filters.state.prevMonth;
	const currentMonth = filters.variables.months.indexOf(filters.state.currentMonth);

	const startDate = moment([
		filters.state.startYear,
		filters.state.startMonth,
		filters.state.startDay
	]);

	const endDate = moment([
		filters.state.endYear,
		filters.state.endMonth,
		filters.state.endDay
	]);

	const firstDayOfMonth = filters.getters.getFirstDayOfMonth();
	let daysInPreviousMonth = filters.state.daysInMonths[previousMonth];
	const previousMonthDays = [];
	for (let times = 0; times < firstDayOfMonth; times++) {
		previousMonthDays.unshift(daysInPreviousMonth);
		daysInPreviousMonth -= 1;
	}

	const numberDaysInCurrentMonth = filters.state.daysInMonths[filters.state.currentMonth];
	const currentMonthDays = [];
	for (let day = 1; day <= numberDaysInCurrentMonth; day++) {
		currentMonthDays.push(day);
	}

	const nextMonthDays = [];
	let nextMonthDay = 1;
	const calendarArea = 42;
	const daysLeftOver = calendarArea - previousMonthDays.length - currentMonthDays.length;
	for (let times = 0; times < daysLeftOver; times++) {
		nextMonthDays.push(nextMonthDay);
		nextMonthDay += 1;
	}

	return (
		<>
			<PreviousMonthDays
				width={width}
				previousMonthDays={previousMonthDays}
				previousMonth={previousMonth}
				currentMonth={currentMonth}
				startDate={startDate}
				endDate={endDate}
			/>

			<CurrentMonthDays
				width={width}
				currentMonthDays={currentMonthDays}
				currentMonth={currentMonth}
				startDate={startDate}
				endDate={endDate}
			/>

			<NextMonthDays
				width={width}
				nextMonthDays={nextMonthDays}
				currentMonth={currentMonth}
				startDate={startDate}
				endDate={endDate}
			/>
		</>
	);
};
