import styled, { css } from "styled-components";

import * as Style from "~/style";

export const Layout = styled("div")`
	${Style.Snippets.size("100vh", "100vw")}
`;

export const Content = styled("main")`
	${Style.Snippets.fill()}
  ${Style.Snippets.absolute()}
`;
