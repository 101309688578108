import * as React from "react";

import * as Styled from "./navbar.styled";

type Props = {
	noPadding?: boolean;
	noDisplay?: boolean;
};

export const Navbar = (props: Props) => {
  const { noPadding, noDisplay } = props;
  
	return (
		<Styled.Navbar noPadding={noPadding} noDisplay={noDisplay}>
			<Styled.NavbarLogoLink to="/">
				<Styled.NavbarLogo />
			</Styled.NavbarLogoLink>
		</Styled.Navbar>
	);
};
