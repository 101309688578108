import styled from "styled-components";
import { animated } from "react-spring";
import * as Gatsby from "gatsby";

import * as Style from "~/style";
import * as Icons from "~/icons";

// ============ //
// ↓↓↓ Tray ↓↓↓ //
// ============ //

export const Tray = styled(animated.div).attrs(() => ({
	backgroundColor: Style.Theme.bind("mode", {
		dark: Style.Constants.search.dark.tray.backgroundColor,
	}),
}))`
	${Style.Snippets.fill()};
	${Style.Snippets.hideScrollbar()};
	position: absolute;
	z-index: 2;
	padding: 20px 0px 40px;
	background-color: ${(props) => props.backgroundColor};
	border-radius: 20px 20px 0px 0px;
	overflow-y: auto;
`;

// ============== //
// ↓↓↓ Header ↓↓↓ //
// ============== //

export const TrayHeader = styled("div").attrs(() => ({
	padding: Style.Theme.bind("device", {
		ultrawide: Style.Snippets.px(Style.Constants.layout.ultrawide.padding),
		desktop: Style.Snippets.px(Style.Constants.layout.desktop.padding),
		tablet: Style.Snippets.px(Style.Constants.layout.tablet.padding),
		mobile: Style.Snippets.px(Style.Constants.layout.mobile.padding),
	}),
}))`
	${Style.Snippets.flex("row", "space-between", "center")};
	margin-bottom: 20px;
	/* padding: 0px ${Style.Constants.layout.mobile.padding}px; */
	padding: ${(props) => props.padding};
	padding-top: 0px;
	padding-bottom: 0px;
`;

export const TrayHeaderButtonLink = styled(Gatsby.Link).attrs(() => ({
	color: Style.Theme.bind("mode", {
		dark: Style.Constants.search.dark.tray.headerButtonLinkColor,
	}),
	backgroundColor: Style.Theme.bind("mode", {
		dark: Style.Constants.search.dark.tray.headerButtonLinkBackgroundColor,
	}),
}))`
	${Style.Snippets.flex()};
	${Style.Snippets.square("35px")};
	color: ${(props) => props.color};
	background-color: ${(props) => props.backgroundColor};
	border-radius: 5px;
	text-decoration: none;
	transition: 0.1s ease-in-out;

	:hover {
		opacity: 0.7;
	}
`;

export const HomeIcon = styled(Icons.Home)`
	${Style.Snippets.square("16px")};
`;

type Close = {
	searched: boolean;
	mode?: string;
};

export const TrayHeaderButtonContainer = styled("div")`
	${Style.Snippets.square("35px")};
`;

export const TrayHeaderButtonClose = styled("div").attrs(() => ({
	color: Style.Theme.bind("mode", {
		dark: Style.Constants.search.dark.tray.headerButtonCloseColor,
	}),
	backgroundColor: Style.Theme.bind("mode", {
		dark: Style.Constants.search.dark.tray.headerButtonCloseBackgroundColor,
	}),
}))<Close>`
	${Style.Snippets.flex()};
	${Style.Snippets.fill()};
	display: ${(props) => (props.searched ? "flex" : "none")};
	color: ${(props) => props.color};
	background-color: ${(props) => (props.searched ? props.backgroundColor : "none")};
	border-radius: 5px;
	cursor: pointer;
	transition: 0.1s ease-in-out;

	:hover {
		opacity: 0.7;
	}
`;

export const CloseIcon = styled(Icons.Close)<Close>`
	${Style.Snippets.square("18px")};
	display: ${(props) => (props.searched ? "block" : "none")};
`;

export const TrayHeaderTitle = styled("h2").attrs(() => ({
	color: Style.Theme.bind("mode", {
		dark: Style.Constants.search.dark.tray.headerTitleColor,
	}),
}))`
	color: ${(props) => props.color};
	font-size: 1rem;
	font-weight: 700;
`;

// =============== //
// ↓↓↓ Content ↓↓↓ //
// =============== //

export const TrayContentContainer = styled("div").attrs(() => ({
	padding: Style.Theme.bind("device", {
		ultrawide: Style.Snippets.px(Style.Constants.layout.ultrawide.padding),
		desktop: Style.Snippets.px(Style.Constants.layout.desktop.padding),
		tablet: Style.Snippets.px(Style.Constants.layout.tablet.padding),
		mobile: Style.Snippets.px(Style.Constants.layout.mobile.padding),
	}),
}))`
	/* padding: 0px ${Style.Constants.layout.mobile.padding}px; */
	padding: ${(props) => props.padding};
	padding-top: 0px;
	padding-bottom: 0px;
	width: 100%;
`;

export const TrayContent = styled("div")`
	${Style.Snippets.grid(1, "auto", 10)}
`;

export const TrayContentCard = styled(animated.a).attrs(() => ({
	target: "_blank",
	rel: "noopener noreferrer",
	color: Style.Theme.bind("mode", {
		dark: Style.Constants.search.dark.tray.contentCardColor,
	}),
	backgroundColor: Style.Theme.bind("mode", {
		dark: Style.Constants.search.dark.tray.contentCardBackgroundColor,
	}),
}))`
	padding: 15px 20px;
	color: ${(props) => props.color};
	background-color: ${(props) => props.backgroundColor};
	border-radius: 5px;
	font-size: 12px;

	text-decoration: none;
`;
