import * as React from "react";
import { Player, BigPlayButton } from "video-react";

import * as Context from "~/context";
import * as Templates from "~/templates";
import * as Components from "~/components";
import resultDummy from "~/images/result-dummy.jpeg";

import * as Styled from "./result.styled";
import { LoadingScreen } from "~/components/loading-screen";

export const Result = () => {
	const { results } = Context.Results.useResultsContext();
	const { theme } = Context.Theme.useThemeContext();
	const { current } = results.state;

	React.useEffect(() => {
		// TODO: Delete this useEffect when we get the actual app working,
		// as the actual app will be able to set the current result when
		// clicking on a card in the search page. This is currently not possible
		// because MirageJS has location conflicts with Gatsby.

		results.getters.getResults();
	}, []);

	console.log(theme.getters.getDeviceIsMobile());

	const allResults = results.state.results;
	const currentResultIndex = Number(results.state.current.video_id);
	const next = allResults[currentResultIndex];

	let nextTitle = "";
	if (next) {
		nextTitle = next.video_title;
		if (nextTitle.length > 62) {
			nextTitle = nextTitle.slice(0, 61) + "...";
		}
	}

	const noDisplay = theme.getters.getDeviceIsMobile();

	return (
		<Templates.Layout>
			<Styled.Result>
				<Components.Navbar noDisplay={noDisplay} />
				<Styled.ResultContainer>
					<LoadingScreen />

					<Styled.ResultVideo>
						<Styled.ResultBackButton to="/search">
							<Styled.ResultBackButtonIcon />
						</Styled.ResultBackButton>

						<Player
							fluid={false}
							height="100%"
							poster={results.state.current.thumbnail}
							src={results.state.current.link}
						>
							<BigPlayButton position="center" />
						</Player>
					</Styled.ResultVideo>

					<Styled.TranscriptNextContainer>
						<Styled.ResultTranscript>"{current.text}"</Styled.ResultTranscript>

						<Styled.ResultNextContainer>
							<Styled.ResultNext>
								<Styled.ResultNextTitle>
									{next ? "Up Next" : "Go Back"}
								</Styled.ResultNextTitle>

								{next ? (
									<ResultNextContent next={next} nextTitle={nextTitle} />
								) : (
									<Styled.ResultHomeLink to="/search">Search</Styled.ResultHomeLink>
								)}
							</Styled.ResultNext>
						</Styled.ResultNextContainer>
					</Styled.TranscriptNextContainer>
				</Styled.ResultContainer>
			</Styled.Result>
		</Templates.Layout>
	);
};

// =========================== //
// ↓↓↓ Result Next Content ↓↓↓ //
// =========================== //

type Props = {
	next: Context.Results.Result;
	nextTitle: string;
};

const ResultNextContent = (props: Props) => {
	const { next, nextTitle } = props;
	const { results } = Context.Results.useResultsContext();

	return (
		<Styled.ResultNextContent
			onClick={() =>
				results.setters.setCurrent(
					next.video_id,
					next.video_title
				)
			}
		>
			<Styled.ResultNextContentThumbnail>
				<Styled.ResultNextContentThumbnailImage src={resultDummy} />
				<Styled.ResultNextContentThumnbailIcon />
			</Styled.ResultNextContentThumbnail>
			<Styled.ResultNextContentInfo>
				<Styled.ResultNextContentInfoTitle>{nextTitle}</Styled.ResultNextContentInfoTitle>
				<Styled.ResultNextContentInfoDate>
					{next ? next.date : ""}
				</Styled.ResultNextContentInfoDate>
				<Styled.ResultNextContentInfoDuration>
					{next ? next.duration : ""}
				</Styled.ResultNextContentInfoDuration>
			</Styled.ResultNextContentInfo>
		</Styled.ResultNextContent>
	);
};
