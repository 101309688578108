import * as React from "react";
import * as Use from "react-use";

import * as Context from "~/context";

import * as Styled from "./result-cards.styled";
import * as Springs from "./result-cards.springs";

import resultDummy from "~/images/result-dummy.jpeg";

export const ResultCards = () => {
	return (
		<Styled.ResultsCards>
			<ResultCard />
		</Styled.ResultsCards>
	);
};

// =================== //
// ↓↓↓ Result Card ↓↓↓ //
// =================== //

export const ResultCard = () => {
	const { results } = Context.Results.useResultsContext();

	const animateCard = Springs.card();

	// Used to re-render component on successful query submit.
	React.useEffect(() => {}, [results.state.results]);

	const resultCards = results.state.results.map((result: Context.Results.Result) => {
		const resultData = [
			result.id,
			result.title,
			result.link,
			result.platform,
			result.transcript,
			result.date,
			result.duration,
			result.thumbnail,
		];

		return (
			<Styled.ResultsCardsCardContainer
				key={result.id}
				style={animateCard}
				onClick={() => results.setters.setCurrent(...resultData)}
			>
				<Styled.ResultsCardsCard>
					<Thumbnail platform={result.platform} />
					<CardData transcript={result.transcript} date={result.date} />
				</Styled.ResultsCardsCard>
			</Styled.ResultsCardsCardContainer>
		);
	});

	return <React.Fragment>{resultCards}</React.Fragment>;
};

// ================= //
// ↓↓↓ Thumbnail ↓↓↓ //
// ================= //

type ThumbnailProps = { platform: string };

const Thumbnail = (props: ThumbnailProps) => {
	const { platform } = props;

	function choosePlatformIcon(platform: string) {
		return platform.toLowerCase() === "youtube" ? (
			<Styled.YouTubeIcon />
		) : platform.toLowerCase() === "instagram" ? (
			<Styled.InstagramIcon />
		) : (
			<Styled.TwitterIcon />
		);
	}

	return (
		<Styled.ResultsCardsCardThumbnailContainer>
			<Styled.ResultsCardsCardThumbnail src={resultDummy} alt="thumbnail" />
			<Styled.ResultsCardsCardThumbnailPlatformIcon>
				{choosePlatformIcon(platform)}
			</Styled.ResultsCardsCardThumbnailPlatformIcon>
		</Styled.ResultsCardsCardThumbnailContainer>
	);
};

// ================= //
// ↓↓↓ Card Data ↓↓↓ //
// ================= //

type CardDataProps = {
	transcript: string;
	date: string;
};

const CardData = (props: CardDataProps) => {
	const { transcript, date } = props;

	return (
		<Styled.ResultsCardsCardData>
			<Styled.ResultsCardsCardDataTranscript>
				{transcript}
			</Styled.ResultsCardsCardDataTranscript>
			<Styled.ResultsCardsCardDataDate>{date}</Styled.ResultsCardsCardDataDate>
		</Styled.ResultsCardsCardData>
	);
};
