import styled from "styled-components";

import * as Style from "~/style";

export const IndexContainer = styled("div")`
	${Style.Snippets.flex("column", "auto", "auto")};
	max-height: 100vh;
`;

export const IndexIntroduction = styled("div")`
	${Style.Snippets.flex("column", "auto", "auto")};
	${Style.Snippets.size("100vh", "100vw")};
	margin-bottom: 1px;
`;

export const IndexATFImage = styled("img").attrs(() => ({
	alt: "Above The Fold Image",
}))`
	/* position: absolute; */
	width: 100%;
`;

type Overlay = { open: boolean };

export const IndexOverlay = styled("div").attrs(() => ({
	backgroundColor: Style.Theme.bind("mode", {
		dark: Style.Constants.home.dark.cardModalOverlayBackgroundColor,
	}),
}))<Overlay>`
	${Style.Snippets.fixed("0px", "0px", "0px", "0px")};
	z-index: 1;
	display: ${(props) => (props.open ? "block" : "none")};
	background-color: ${(props) => props.backgroundColor};
`;
