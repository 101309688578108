import * as React from "react";
import * as Helmet from "react-helmet";

import * as Query from "~/gatsby/lib/query";

type SEO = {
	description?: string;
	lang?: string;
	meta?: object;
	title?: string;
	author?: string;
};

export function SEO(props: SEO) {
	const {
		title = props.title,
		author = props.author,
		description = props.author,
		lang = props.lang || "en"
	} = Query.Site.Metadata.useSiteMetadata();

	const meta = [
		{
			name: `description`,
			content: description
		},
		{
			property: `og:title`,
			content: title
		},
		{
			property: `og:description`,
			content: description
		},
		{
			property: `og:type`,
			content: `website`
		},
		{
			name: `twitter:card`,
			content: `summary`
		},
		{
			name: `twitter:creator`,
			content: author
		},
		{
			name: `twitter:title`,
			content: title
		},
		{
			name: `twitter:description`,
			content: description
		}
	];

	return (
		<Helmet.Helmet
			htmlAttributes={{ lang }}
			title={title}
			titleTemplate={`%s | ${title}`}
			meta={meta}
		/>
	);
}

export default SEO;
