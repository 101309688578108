import * as React from "react";

import * as Styled from "./svgs.styled";

export const Logo = () => {
	return (
		<Styled.Logo>
			<svg
        width="60"
        height="60"
				viewBox="0 0 60 60"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M30 60C22.0435 60 14.4129 56.8393 8.7868 51.2132C3.1607 45.5871 -2.21335e-07 37.9565 0 30C2.21335e-07 22.0435 3.16071 14.4129 8.7868 8.7868C14.4129 3.1607 22.0435 -9.48802e-08 30 0L30 30L30 60Z"
					fill="#FD423D"
				/>
				<path
					d="M9.66103 30C9.66103 35.3942 11.8039 40.5675 15.6182 44.3818C19.4325 48.1961 24.6058 50.339 30 50.339C35.3942 50.339 40.5675 48.1961 44.3818 44.3818C48.1961 40.5675 50.339 35.3942 50.339 30L30 30H9.66103Z"
					fill="#FEBB2C"
					fillOpacity="0.8"
				/>
			</svg>
		</Styled.Logo>
	);
};
