import styled, { css } from "styled-components";
import * as Gatsby from "gatsby";
import * as Scroll from "react-scroll";

import * as Style from "~/style";
import * as Icons from "~/icons";

// ============== //
// ↓↓↓ Footer ↓↓↓ //
// ============== //

export const Footer = styled("div").attrs(() => ({
	padding: Style.Theme.bind("device", {
		ultrawide: Style.Snippets.px(Style.Constants.layout.desktop.padding),
		desktop: Style.Snippets.px(Style.Constants.layout.desktop.padding),
		tablet: Style.Snippets.px(Style.Constants.layout.tablet.padding),
		mobile: Style.Snippets.px(Style.Constants.layout.mobile.padding),
	}),
	backgroundColor: Style.Theme.bind("mode", {
		dark: Style.Constants.footer.dark.backgroundColor,
	}),
}))`
	position: relative;
	margin-top: -1px;
	padding: ${(props) => props.padding};
	background-color: ${(props) => props.backgroundColor};
`;

// ================== //
// ↓↓↓ Top Button ↓↓↓ //
// ================== //

export const FooterBackToTopButton = styled(Scroll.Link).attrs(() => ({
	display: Style.Theme.bind("device", {
		ultrawide: "none",
		desktop: "none",
		tablet: "auto",
		mobile: "auto",
	}),
	color: Style.Theme.bind("mode", {
		dark: Style.Constants.footer.dark.topButtonColor,
	}),
	backgroundColor: Style.Theme.bind("mode", {
		dark: Style.Constants.footer.dark.topButtonBackgroundColor,
	}),
	border: Style.Theme.bind("mode", {
		dark: Style.Constants.footer.dark.topButtonBorderColor,
	}),
	boxShadow: Style.Theme.bind("mode", {
		dark: Style.Constants.footer.dark.topButtonShadow,
	}),
}))`
	${Style.Snippets.center()};
	position: absolute;
	top: 0;
	left: 50%;
	display: ${(props) => props.display};
	padding: 15px 20px;
	color: ${(props) => props.color};
	background-color: ${(props) => props.backgroundColor};
	border: ${(props) => props.border} solid 1px;
	border-radius: 5px;
	font-size: 16px;

	box-shadow: ${(props) => props.boxShadow};
	cursor: pointer;
`;

// ========================= //
// ↓↓↓ Footer Navigation ↓↓↓ //
// ========================= //

export const FooterNavContainer = styled("div").attrs(() => ({
	width: Style.Theme.bind("device", {
		ultrawide: Style.Snippets.px(450),
		desktop: Style.Snippets.px(450),
		tablet: Style.Snippets.px(350),
		mobile: Style.Snippets.px(250),
	}),
}))`
	${Style.Snippets.flex("row", "space-between", "auto")};
	width: ${(props) => props.width};
	margin: 0px auto 50px;
`;

export const FooterNav = styled("div")``;

export const FooterNavTitle = styled("div").attrs(() => ({
	color: Style.Theme.bind("mode", {
		dark: Style.Constants.footer.dark.navTitleColor,
	}),
	fontSize: Style.Theme.bind("device", {
		ultrawide: Style.Snippets.px(40),
		desktop: Style.Snippets.px(40),
		tablet: Style.Snippets.px(30),
		mobile: Style.Snippets.px(20),
	}),
}))`
	margin-bottom: 10px;
	color: ${(props) => props.color};
	font-size: ${(props) => props.fontSize};
	font-weight: 700;
`;

export const FooterNavLinks = styled("div")`
	${Style.Snippets.flex("column", "auto", "auto")};
`;

export const FooterNavLocalLink = styled(Gatsby.Link).attrs(() => ({
	color: Style.Theme.bind("mode", {
		dark: Style.Constants.footer.dark.navLocalLinkColor,
	}),
}))`
	color: ${(props) => props.color};
	text-decoration: none;
	line-height: 200%;
`;

export const FooterNavExternalLink = styled("a").attrs((props) => ({
	target: "_blank",
	rel: "noopener noreferrer",
	color: Style.Theme.bind("mode", {
		dark: Style.Constants.footer.dark.navExternalLinkColor,
	}),
}))`
	color: ${(props) => props.color};
	text-decoration: none;
	line-height: 200%;
`;

// ====================== //
// ↓↓↓ Footer Socials ↓↓↓ //
// ====================== //

export const FooterSocials = styled("div").attrs(() => ({
	width: Style.Theme.bind("device", {
		ultrawide: Style.Snippets.px(450),
		desktop: Style.Snippets.px(450),
		tablet: Style.Snippets.px(350),
		mobile: Style.Snippets.px(250),
	}),
}))`
	${Style.Snippets.flex("row", "space-between", "center")};
	margin: 0px auto;
	width: ${(props) => props.width};
`;

function socialStyles(color: string) {
	return css`
		${Style.Snippets.circle()};
		${Style.Snippets.size(`${Style.Constants.footer.mobile.iconHeight}px`, "auto")};
		padding: 8px;
		color: ${color};
	`;
}

export const FooterSocialsLink = styled("a").attrs((props) => ({
	href: props.href,
	target: "_blank",
	rel: "noopener noreferrer",
}))``;

export const FooterSocialsFacebook = styled(Icons.Facebook).attrs(() => ({
	backgroundColor: Style.Theme.bind("mode", {
		dark: Style.Constants.footer.dark.socialsBackgroundColor,
	}),
}))`
	${socialStyles(Style.Constants.globalColors.socials.mutedDark)};
	background-color: ${(props) => props.backgroundColor};
`;

export const FooterSocialsInstagram = styled(Icons.Instagram).attrs(() => ({
	backgroundColor: Style.Theme.bind("mode", {
		dark: Style.Constants.footer.dark.socialsBackgroundColor,
	}),
}))`
	${socialStyles(Style.Constants.globalColors.socials.mutedDark)};
	background-color: ${(props) => props.backgroundColor};
`;

export const FooterSocialsTwitter = styled(Icons.Twitter).attrs(() => ({
	backgroundColor: Style.Theme.bind("mode", {
		dark: Style.Constants.footer.dark.socialsBackgroundColor,
	}),
}))`
	${socialStyles(Style.Constants.globalColors.socials.mutedDark)};
	background-color: ${(props) => props.backgroundColor};
`;

export const FooterSocialsYouTube = styled(Icons.Youtube).attrs(() => ({
	backgroundColor: Style.Theme.bind("mode", {
		dark: Style.Constants.footer.dark.socialsBackgroundColor,
	}),
}))`
	${socialStyles(Style.Constants.globalColors.socials.mutedDark)};
	background-color: ${(props) => props.backgroundColor};
`;
