import * as React from "react";

import * as Context from "~/context";

import * as Styled from "./loading-screen.styled";
import * as Springs from "./loading-screen.springs";

export const LoadingScreen = () => {
	const { loading } = Context.Loading.useLoadingContext();

	React.useEffect(() => {
		setTimeout(() => {
			loading.setters.setLoaded(true);
		}, 2000);
	}, []);

	const animateScreen = Springs.screen(loading.state.loaded);
	const animateIcon = Springs.icon(
		loading.state.spin,
		loading.setters.toggleSpin,
		loading.state.loaded
	);

	return (
		<Styled.LoadingScreen loaded={loading.state.loaded} style={animateScreen}>
			<Styled.LoadingScreenIconContainer style={animateIcon}>
				<Styled.LoadingScreenIcon />
			</Styled.LoadingScreenIconContainer>
		</Styled.LoadingScreen>
	);
};
