import styled from "styled-components";

import * as Style from "~/style";

// ============ //
// ↓↓↓ Sort ↓↓↓ //
// ============ //

export const Sort = styled("div").attrs(() => ({
	backgroundColor: Style.Theme.bind("mode", {
		dark: Style.Constants.filter.dark.sort.backgroundColor,
	}),
}))`
	padding: 40px ${Style.Constants.layout.mobile.padding}px;
	background-color: ${(props) => props.backgroundColor};
	border-radius: 0px 0px 40px 40px;
`;

export const SortTitle = styled("div").attrs(() => ({
	color: Style.Theme.bind("mode", {
		dark: Style.Constants.filter.dark.sort.titleColor,
	}),
}))`
	margin-bottom: 20px;
	color: ${(props) => props.color};
	font-size: 14px;
	font-weight: 700;
`;

// ============== //
// ↓↓↓ Button ↓↓↓ //
// ============== //

export const SortButtons = styled("div")`
	${Style.Snippets.grid(3, "1fr", 20)};
	width: 100%;
`;

type SortButton = { selected: boolean };

export const SortButton = styled("button").attrs(() => ({
	colorSelected: Style.Theme.bind("mode", {
		dark: Style.Constants.filter.dark.sort.buttonTextColorSelected,
	}),
	colorNotSelected: Style.Theme.bind("mode", {
		dark: Style.Constants.filter.dark.sort.buttonTextColorNotSelected,
	}),
	backgroundColorSelected: Style.Theme.bind("mode", {
		dark: Style.Constants.filter.dark.sort.buttonBackgroundColorSelected,
	}),
	backgroundColorNotSelected: Style.Theme.bind("mode", {
		dark: Style.Constants.filter.dark.sort.buttonBackgroundColorNotSelected,
	}),
	borderSelected: Style.Theme.bind("mode", {
		dark: `${Style.Constants.filter.dark.sort.buttonBorderColorSelected} solid 1px`,
	}),
	borderNotSelected: Style.Theme.bind("mode", {
		dark: `${Style.Constants.filter.dark.sort.buttonBorderColorNotSelected} solid 1px`,
	}),
}))<SortButton>`
	padding: 10px;
	color: ${(props) => (props.selected ? props.colorSelected : props.colorNotSelected)};
	background-color: ${(props) => {
		return props.selected
			? props.backgroundColorSelected
			: props.backgroundColorNotSelected;
	}};
	border: ${(props) => {
		return props.selected ? props.borderSelected : props.borderNotSelected;
	}};
	border-radius: 5px;
	transition: 0.15s ease-in-out;
	outline: none;
`;
