import * as React from "react";

import * as Context from "~/context";

import * as Styled from "./tray.styled";
import * as Springs from "./tray.springs";

type Props = {
	input: string;
};

export const Tray = (props: Props) => {
	const { search } = Context.Search.useSearchContext();
	const { results } = Context.Results.useResultsContext();

	const animateBody = Springs.body(search.state.searching);
	const animateCard = Springs.card();

	let content;
	if (props.input === "") {
		content = search.state.recommendations.map((recommendation: Context.Search.Tray) => {
			return (
				<Styled.TrayContentCard
					key={`recommendation-${recommendation.id}`}
					href={recommendation.link}
					style={animateCard}
				>
					{recommendation.content}
				</Styled.TrayContentCard>
			);
		});
	} else {
		content = search.state.suggestions.map((suggestion: Context.Search.Tray) => {
			return (
				<Styled.TrayContentCard
					key={`suggestion-${suggestion.id}`}
					href={suggestion.link}
					style={animateCard}
				>
					{suggestion.content}
				</Styled.TrayContentCard>
			);
		});
	}

	return (
		<Styled.Tray style={animateBody}>
			<Styled.TrayHeader>
				<Styled.TrayHeaderButtonLink to="/">
					<Styled.HomeIcon />
				</Styled.TrayHeaderButtonLink>
				<Styled.TrayHeaderTitle>{search.state.trayType}</Styled.TrayHeaderTitle>
				<Styled.TrayHeaderButtonContainer>
					<Styled.TrayHeaderButtonClose
						searched={results.state.results.length > 0}
						onClick={() => search.setters.setSearching(true)}
					>
						<Styled.CloseIcon searched={results.state.results.length > 0} />
					</Styled.TrayHeaderButtonClose>
				</Styled.TrayHeaderButtonContainer>
			</Styled.TrayHeader>

			<Styled.TrayContentContainer>
				<Styled.TrayContent>{content}</Styled.TrayContent>
			</Styled.TrayContentContainer>
		</Styled.Tray>
	);
};
