import * as React from "react";

import * as Styled from "./description.styled";

export const Description = () => {
	return (
		<Styled.Description>
			<Styled.DescriptionTitle>A New Way To Search</Styled.DescriptionTitle>
			<Styled.DescriptionSubtitle>
				No more memorizing the title of a video or keywords anymore. Just search based on
				key words or exact matches.
			</Styled.DescriptionSubtitle>

			<Styled.DescriptionExamples>
				<Styled.DescriptionExample justifySelf={"start"}>
					<Styled.DescriptionExampleIcon />
					<Styled.DescriptionExampleText>twitch</Styled.DescriptionExampleText>
				</Styled.DescriptionExample>

				<Styled.DescriptionExample justifySelf={"end"}>
					<Styled.DescriptionExampleIcon />
					<Styled.DescriptionExampleText>
						“the reason for my success”
					</Styled.DescriptionExampleText>
				</Styled.DescriptionExample>
			</Styled.DescriptionExamples>
		</Styled.Description>
	);
};
