import * as React from "react";
import moment from "moment";

import * as Context from "~/context";

import * as Styled from "../date.styled";

type Props = {
  width: number;
  previousMonthDays: number[];
	previousMonth: string;
	currentMonth: number;
	startDate: moment.Moment;
  endDate: moment.Moment;
};

export const PreviousMonthDays = (props: Props) => {
	const { width, previousMonthDays, previousMonth, currentMonth, startDate, endDate } = props;
	const { filters } = Context.Filters.useFiltersContext();

	const component = previousMonthDays.map((day: number, index: number) => {
		let previousMonth = currentMonth - 1;
		if (previousMonth < 0) previousMonth = 11;
		const month = filters.variables.months[previousMonth];

		return (
			<Styled.DateCalendarBodyDaysDay
				key={`previous-${index}`}
				onClick={() =>
					filters.setters.setRange(previousMonth, day, filters.state.currentYear)
				}
				inCurrentMonth={false}
				startDate={startDate}
				endDate={endDate}
				date={moment([
					month === "December"
						? filters.state.currentYear - 1
						: filters.state.currentYear,
					previousMonth,
					day
				])}
				windowWidth={width}
			>
				{day}
			</Styled.DateCalendarBodyDaysDay>
		);
	});

	return <>{component}</>;
};
