import styled from "styled-components";

import * as Style from "~/style";

// ================== //
// ↓↓↓ Handpicked ↓↓↓ //
// ================== //

export const HandpickedContainer = styled("div").attrs(() => ({
	backgroundColor: Style.Theme.bind("mode", {
		dark: Style.Constants.home.dark.handpicked.backgroundColor
	})
}))`
	${Style.Snippets.flex("column", "auto", "auto")};
	position: absolute;
	bottom: 0;
	padding: 0px 0px 30px 0px;
	width: 100%;
	background-color: ${(props) => props.backgroundColor};
	border-radius: 30px 30px 0px 0px;
	opacity: 0.8;
`;

export const HandpickedTitle = styled("div").attrs(() => ({
	color: Style.Theme.bind("mode", {
		dark: Style.Constants.home.dark.handpicked.titleColor
	})
}))`
	margin: 30px 0;
	color: ${(props) => props.color};
	font-size: 22px;
	font-weight: 700;
	text-align: center;
`;

// ======================== //
// ↓↓↓ Handpicked Cards ↓↓↓ //
// ======================== //

type Cards = {
	totalCards: number;
};

export const HandpickedCardsContainer = styled("div").attrs((props: Cards) => ({
	totalCards: props.totalCards
}))`
	${Style.Snippets.hideScrollbar()};
	display: grid;
	grid-template-columns: ${(props) => `repeat(${props.totalCards}, 1fr)`};
	grid-gap: 20px;
	align-items: center;
	padding: 0px 20px;
	overflow-x: auto;
`;

export const HandpickedCardsItem = styled("div").attrs(() => ({
	color: Style.Theme.bind("mode", {
		dark: Style.Constants.home.dark.handpicked.card.textColor
	}),
	backgroundColor: Style.Theme.bind("mode", {
		dark: Style.Constants.home.dark.handpicked.card.backgroundColor
	})
}))`
	${Style.Snippets.size("250px", "200px")};
	padding: 20px;
	color: ${(props) => props.color};
	background-color: ${(props) => props.backgroundColor};
	border-radius: 10px;
	font-size: 18px;
	font-weight: 700;
	line-height: 150%;
	text-decoration: none;
	opacity: 1;
	cursor: pointer;
`;
