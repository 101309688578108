import * as React from "react";

import * as Templates from "~/templates";

import * as Styled from "./layout.styled";

type LayoutProps = React.PropsWithChildren<{}>;

export const Layout = (props: LayoutProps) => {
	const { children } = props;

	return (
		<Styled.Layout>
			<Templates.Observer>
				<Styled.Content>{children}</Styled.Content>
			</Templates.Observer>
		</Styled.Layout>
	);
};
